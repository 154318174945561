import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { clearAuth, serverGet } from '../../middleware/http';
import { apiConst } from '../constant/constant';
import { getAuthProfile } from '../utils/validation';
//import ProgressContext from '../utils/progress';

const ManageResourceontext = createContext(null);

export const useManageResource = () => {
    return useContext(ManageResourceontext);
};


export const ResourceProvider = ({ children }) => {
    const [resourceList, setResourceList] = useState(null);
    const [portalResourceContent, setPortalResourceContent] = useState(null);

    const location = useLocation();


    const getResource = () => {
        serverGet(`${apiConst.getResource}`).then((res) => {
            try {
                if (res?.data !== null && res?.status == 1) {
                    setResourceList(res?.data);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const getPortalResourceContent = async () => {
        try {
            const res = await serverGet(apiConst.getPortalContent);
            if (res?.data  && res?.status == 1) {
                setPortalResourceContent(res?.data);
            }          
        } catch (error) {
            console.error(error);
        }
    }

    const getCurrentResource = (id, pcode) => {
        if (Array.isArray(resourceList?.resources)) {
            const data = [...resourceList?.resources];
            const _parentId = data.find((list) => list?.code == pcode);

            const _currentRes = data.filter((list) => pcode ? list?.parentId == _parentId?.id && list.typeId == id : list.typeId == id);
            return _currentRes;
        } else {
            // console.log("calling else");
            serverGet(`${apiConst.getResource}`).then((res) => {
                try {
                    if (res?.data !== null && res?.status == 1) {
                        setResourceList(res?.data);
                    }
                } catch (e) {
                    console.log(e);
                }
            })
        }
    }


    const getCurrentResourceAction = (code, pCode) => {
        if (Array.isArray(resourceList?.resources) && Array.isArray(resourceList?.actions)) {
            const data = [...resourceList?.resources];
            let parentId = "";

            if (pCode)
                parentId = data.find((list) => list?.code == code || list?.parentId == pCode)?.parentId;

            const _resource = data.find((list) => parentId ? list?.parentId == parentId && list?.code == code : list?.code == code);
            if (_resource) {
                const _action = resourceList?.actions?.filter((list) => list?.resourceId == _resource?.id);
                if (_action?.length > 0) {
                    return _action;
                }
            }
        } else {
            serverGet(`${apiConst.getResource}`).then((res) => {
                try {
                    if (res?.data !== null && res?.status == 1) {
                        setResourceList(res?.data);
                    }
                } catch (e) {
                    console.log(e);
                }
            })
        }
    }


    const actionExists = (arr, actioncode, feautureCode) => {
        if (Array.isArray(arr)) {
            const actionArray = arr?.filter((list) => list?.code == actioncode);
            if (feautureCode && Array.isArray(actionArray) && actionArray?.length > 0) {
                const _featureCode = actionArray[0]?.featureCodes?.split("~");
                return _featureCode?.includes(feautureCode);
            } else {
                return arr?.some((list) => list?.code == actioncode);
            }
        }
    }
    //getAuthProfile.getProfileAuth() &&
    useEffect(() => {
        if (!resourceList) {
            getResource();
        }

        if (!portalResourceContent) {
            getPortalResourceContent();
        }

        
    }, [location]);


    return (
        <ManageResourceontext.Provider value={{ resourceList, getCurrentResource, getCurrentResourceAction, actionExists, setResourceList, portalResourceContent }}>
            {children}
        </ManageResourceontext.Provider>
    );
};



