import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import Message from './message'
import MessageList from './messageList'
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import AddMessage from './AddMessage';
import ProgressContext from '../../../services/utils/progress';
import { serverGet } from '../../../middleware/http';
import { apiConst, resourceConst } from '../../../services/constant/constant';
import { useManageResource } from '../../../services/Context/ManageResourceContext';
import { getSearchParams, removeSearchParam, updateSearchParams } from '../../../services/utils/validation';


export default function MessageHome() {

    const [list, setList] = useState(null);
    const [msgId, setMsgId] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [resourceAction, setResourceAction] = useState([]);

    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();

    const { getLanguageContent } = useLanguageRes();

    const { progParams } = useContext(ProgressContext);


    const handleBack = () => {
        setMsgId(null);
        removeSearchParam("id");

    }



    const toggleMessage = () => {
        setMsgId(null);
        removeSearchParam("id");

        setShowMessage(!showMessage);

    }

    const getInboxList = () => {
        progParams(true);
        if (!list)
            setList("loading");
        serverGet(apiConst.getMessage).then((res) => {
            progParams(false)
            try {
                if (res?.data && res?.status == 1) {
                    setList(res?.data);
                } else {
                    setList(null);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    useEffect(() => {
        if (msgId) {
            updateSearchParams("id", msgId);
        }
        const _id = getSearchParams("id");
        if (_id) {
            //setMsgId("a61d8a3d-2a91-4324-aecc-c1575daa4b44");
            setMsgId(_id);

        }

    }, [msgId])

    useEffect(() => {

        const _action = getCurrentResourceAction("XK8")
        setResourceAction(_action);

    }, [resourceList])

    useEffect(() => {
        document.title = "Message"
        getInboxList();
    }, []);


    //console.log(resourceAction);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div >
                    <h4 className="m-0">{showMessage ? getLanguageContent("msgnew") : getLanguageContent("msgage")}</h4>
                </div>
                {(!showMessage && actionExists(resourceAction, resourceConst?.actions?.create)) && (
                    <div>
                        <button onClick={toggleMessage} className="btn btn-primary">{getLanguageContent("msgnew")}</button>
                    </div>
                )}
            </div>
            {!showMessage && (
                <div className="row">
                    <div className={`col-xl-5  ${!msgId ? "" : "d-none d-xl-block"}`}>
                        <MessageList list={list} setMsgId={setMsgId} msgId={msgId} />
                    </div>
                    <div className={`col-xl-7 ${msgId ? "" : "d-none d-xl-block"}`}>
                        <Message msgId={msgId} handleBack={handleBack} list={list} getInboxList={getInboxList} edit={actionExists(resourceAction, resourceConst?.actions?.create)} />
                    </div>
                </div>
            )}
            {showMessage && (
                <div className="card border-0 ">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-7">
                                <AddMessage toggleMessage={toggleMessage} msgId={msgId} getInboxList={getInboxList} level="new" backButton={toggleMessage} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}