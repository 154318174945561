import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActionStatus from "../../../services/utils/actionStatus";

export default function PaymentFailed() {

    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false);




    useEffect(() => {  
        const preventBack = () => {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };

        preventBack();

        return () => {
            window.onpopstate = null;
        };

    }, []);



    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const click = urlSearchParams.get('click');

        if (click == "yes") {
            setIsClicked(true);
            document.title = "Cancelled"
        } else {
            document.title = "Failed"
        }    
    }, []);
 
    


    return (
        <div className="bg-white rounded d-flex justify-content-center align-items-center" style={{ minHeight: "78.5vh" }}>
            <div className="text-center">
                <ActionStatus description={""} button={""} fill="#DC3545" imageid="#icons_errors" onsyncchange={""} type="search" showSvg={true} />
                <h2 className="text-danger">Payment {isClicked ? "cancelled" : "failed"}</h2>
                <Link to="/dashboard">
                    <h6>Back to dashboard <span>
                        <svg className="ms-1" width="17" height="17" role="img"><use xlinkHref="#svg_tabactive" /></svg>
                    </span></h6>
                </Link>
            </div>
        </div>
    )
}


