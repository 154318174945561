import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { serverGet } from '../../middleware/http';
import { apiConst, appConst } from '../constant/constant';
//import ProgressContext from '../utils/progress';
import { getSearchParams } from '../utils/validation';

const DoctorContext = createContext(null);

export const useDoctor = () => {
    return useContext(DoctorContext);
};


export const DoctorProvider = ({ children }) => {
    const [doctorContext, setDoctorContext] = useState(null);
    const [memberId, setMemberId] = useState(null);
    const [doctorContextLoader, setDoctorContextLoader] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    //const { progParams } = useContext(ProgressContext);

    const providerprofile = async (id) => {
        try {
            //progParams(true);
            let member_id = queryParams.get('memberid');
            setDoctorContextLoader(true);

            const _typeId = getSearchParams("ftypeid");
            const specialityId = getSearchParams("spid");

            const pricingAtr = [
                {
                    attributeid: "101",
                    recordtypeid: "33",
                    recordid: _typeId ? _typeId : "1"
                },
                {
                    attributeid: "102",
                    recordtypeid: "32",
                    recordid: specialityId
                }
            ]

            if (id) {
                member_id = id?.toLowerCase();
            }
            const res = await serverGet(`${apiConst.getproviderprofile}/${member_id}?atr=${JSON.stringify(pricingAtr)}`)
            if (res?.data !== null && res?.status == 1) {
                setDoctorContext(res?.data)
                if (id) {
                    return res;
                }
            }
        } catch (e) {
            console.error(e)
        } finally {
            setDoctorContextLoader(false);

           // progParams(false)

        }
    }


    useEffect(() => {

        const member_id = queryParams.get('memberid');
        let _brtype = queryParams.get('brtype');

        if (member_id && memberId !== '' && location.pathname != "/profiledetail") {
           /* if (member_id !== memberId) {*/
            setMemberId(member_id);
            if (_brtype != appConst?.resourceCodes.consultNowWithoutProvider)
                providerprofile();
        //    }
        }
        if (_brtype != appConst?.resourceCodes.consultNowWithoutProvider && doctorContext) {
            setDoctorContext(null);
        }


    }, [location]);


    return (
        <DoctorContext.Provider value={{ doctorContext, providerprofile, doctorContextLoader }}>
            {children}
        </DoctorContext.Provider>
    );
};



