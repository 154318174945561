import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { serverGet } from '../../middleware/http';
import { apiConst } from '../constant/constant';
import ProgressContext from '../utils/progress';

const LanguageResContext = createContext(null);

export const useLanguageRes = () => {
    return useContext(LanguageResContext);
};


export const LanguageResProvider = ({ children }) => {
    const [languageRes, setLanguageRes] = useState([]);

    //const location = useLocation();
    //const queryParams = new URLSearchParams(location.search);
    //const memberId = queryParams.get('memberid');
    const { progParams } = useContext(ProgressContext);

    const getLanguageRes = (value) => {
        progParams(true);
        serverGet(`${apiConst.getlangresource}?ln=${"en-us"}`).then((res) => {
            progParams(false);
            try {
                if (res?.data !== null && res?.status == 1)
                    setLanguageRes(res?.data);
            } catch (e) {
                console.log(e);
            }
        })
    }

    const getLanguageContent = (key) => {
        
        //const language_content = [...languageRes];

        //if (language_content !== null && typeof langResources != "string" && language_content?.length > 0) {
        //    return key && key != undefined && key != null ? language_content.find(lang => lang.key === key)?.value : "";
        //} else {
        //    return "";
        //}


        if (languageRes !== null && typeof languageRes != "string" && languageRes?.length > 0) {
            return key && key != undefined && key != null ? languageRes.find(lang => lang.key === key)?.value : "";
        } else {
            return "";
        }

        
    }

    useEffect(() => {
        //if (!window.location.pathname.includes('security'))
            getLanguageRes();
        
    }, []);

    //useEffect(() => {
    //    if (!window.location.pathname.includes('security') && (languageRes == null || typeof langResources == "string" || languageRes?.length< 0))
    //        getLanguageRes();

    //}, []);


    return (
        <LanguageResContext.Provider value={{ languageRes, getLanguageContent, getLanguageRes }}>
            {children}
        </LanguageResContext.Provider>
    );
};



