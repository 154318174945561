//import React, { useState } from "react";

//const AudioRecorder = () => {
//    const [permission, setPermission] = useState(false);
//    const [stream, setStream] = useState(null);
//    const [mediaRecorder, setMediaRecorder] = useState(null);
//    const [isRecording, setIsRecording] = useState(false);
//    const [audioChunks, setAudioChunks] = useState([]);
//    const [audioUrl, setAudioUrl] = useState(null);

//    const getMicrophonePermission = async () => {
//        if ("MediaRecorder" in window) {
//            try {
//                const streamData = await navigator.mediaDevices.getUserMedia({
//                    audio: true,
//                    video: false,
//                });
//                setPermission(true);
//                setStream(streamData);
//                setupMediaRecorder(streamData);
//            } catch (err) {
//                alert(err.message);
//            }
//        } else {
//            alert("The MediaRecorder API is not supported in your browser.");
//        }
//    };

//    const setupMediaRecorder = (streamData) => {
//        const recorder = new MediaRecorder(streamData);
//        recorder.ondataavailable = (e) => {
//            if (e.data.size > 0) {
//                setAudioChunks([...audioChunks, e.data]);
//            }
//        };
//        recorder.onstop = () => {
//            const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
//            const url = URL.createObjectURL(audioBlob);
//            setAudioUrl(url);
//        };
//        setMediaRecorder(recorder);
//    };

//    const toggleRecording = () => {
//        if (isRecording) {
//            mediaRecorder.stop();
//        } else {
//            mediaRecorder.start();
//        }
//        setIsRecording(!isRecording);
//    };


//    const playRecording = () => {
//        if (audioUrl) {
//            const audioElement = new Audio(audioUrl);
//            audioElement.addEventListener('error', (e) => {
//                console.error('Error loading audio:', e);
//            });
//            audioElement.play().catch((error) => {
//                console.error('Error playing audio:', error);
//            });
//        }
//    };


//    console.log(audioUrl,"audioUrl")

//    return (
//        <div>
//            <h2>Audio Recorder</h2>
//            <main>
//                <div className="audio-controls">
//                    {!permission ? (
//                        <button onClick={getMicrophonePermission} type="button">
//                            Get Microphone
//                        </button>
//                    ) : (
//                        <button onClick={toggleRecording} type="button">
//                            {isRecording ? "Stop Recording" : "Start Recording"}
//                        </button>
//                    )}
//                    {audioUrl && (
//                        <button onClick={playRecording} type="button">
//                            Preview
//                        </button>
//                    )}
//                </div>
//            </main>
//        </div>
//    );
//};

//export default AudioRecorder;

import React, { useState, useRef } from 'react';

const VoiceRecorder = ({ setAudioFile }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorder = useRef(null);
    const audioStream = useRef(null);
    const [base64Data, setBase64Data] = useState(null);


    const startRecording = () => {
        if (!isRecording) {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    audioStream.current = stream;
                    mediaRecorder.current = new MediaRecorder(stream);

                    mediaRecorder.current.ondataavailable = (e) => {
                        if (e.data.size > 0) {
                            setAudioBlob(e.data);
                            setAudioFile(e.data);
                        }
                    };

                    mediaRecorder.current.start();
                    setIsRecording(true);
                })
                .catch((error) => {
                    console.error('Error accessing the microphone:', error);
                });
        } else {
            stopRecording();
        }
    };

    const stopRecording = () => {
        if (isRecording) {
            mediaRecorder.current.stop();
            audioStream.current.getTracks().forEach((track) => track.stop());
            setIsRecording(false);
        }
    };

    const handlePreview = () => {
        if (audioBlob) {

            getData(audioBlob, (base64) => {
                setBase64Data(base64); // Set base64 data in the state
            });


            const audioURL = URL.createObjectURL(audioBlob);

            // Create an <audio> element and set its source to the audio URL
            const audioElement = new Audio(audioURL);

            // Play the audio
            audioElement.play();
        }
    };


    function getData(audioFile, callback) {
        var reader = new FileReader();
        reader.onload = function (event) {
            var base64Audio = event.target.result;
            callback(base64Audio);
        };
        reader.readAsDataURL(audioFile);
    }


    //console.log(base64Data);
    //console.log(isRecording);

    return (
        <>
            <button type="button" className="btn btn-primary rounded-circle position-relative" onClick={startRecording}>
                {isRecording ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stop-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mic" viewBox="0 0 16 16">
                    <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                    <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>}
                {isRecording &&
                    <span className="position-absolute top-0 start-100 translate-middle spinner-grow text-danger spinner-grow-sm" role="status">
                    </span>
                }
            </button>
        </>
    );
};

export default VoiceRecorder;




//<button className="btn btn-primary rounded-circle position-relative" onClick={startRecording}>
            //    {isRecording ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stop-circle" viewBox="0 0 16 16">
            //        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            //        <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z" />
            //    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mic" viewBox="0 0 16 16">
            //        <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
            //        <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
            //    </svg>}

            //    {isRecording &&
            //        <span className="spinner-grow text-success" role="status">
            //        </span>
            //    }
            //</button>


//{
//    audioBlob && <button className="btn btn-primary" onClick={handlePreview}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stop-circle" viewBox="0 0 16 16">
//        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
//        <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z" />
//    </svg></button>
//}