import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import { useDoctor } from '../../../../services/Context/DoctorContext';
import { useProfile } from '../../../../services/utils/profileContext';
import { getSearchParams, isEnabled, timeFormat, updateSearchParams } from '../../../../services/utils/validation';
//import { getConsultationMode, getEncounterMode } from './ScheduleConfirmation';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';
import { apiConst, appConst } from '../../../../services/constant/constant';
import { serverGet } from '../../../../middleware/http';
import LazyImageComponent from '../../../../services/utils/LazyImageComponent';

export default function DoctorSummary() {
    const { doctorContext, doctorContextLoader } = useDoctor();
    const { profileContext, contextFamilyList } = useProfile();
    const [isLeftCollapsed, setLeftCollapsed] = useState(false);
    const [pharmacy, setPharmacy] = useState(null);
    const [pharmacyId, setPharmacyId] = useState(null);
    const [speciality, setSpeciality] = useState(null);
    const { getLanguageContent } = useLanguageRes();
    const { accountDate, showAccPricing, AccountCurrency, accountSettings, cateogrySettings, schedulePricing } = useAccountSettings();
    const location = useLocation();
    const navigate = useNavigate();
    const [summary, setSummary] = useState(null);

    const providerprofile = () => {
        const locationSearch = window.location.search
        navigate(`/profiledetail${locationSearch}`);
    }

    const getPharmacy = (id) => {
        serverGet(`${apiConst.getPharmacy}${id}`).then((res) => {
            try {
                if (res.data && res.data !== null && res?.status == 1)
                    setPharmacy(res?.data);
            } catch (error) {
                console.log(error);
            }
        })
    }


    const findFamilyMember = (id) => {
        return contextFamilyList?.find((list) => list?.id == getSearchParams("fmid"));
    }

    const getSpeciality = () => {
        const _specialityId = getSearchParams('spid');
        if (_specialityId) {
            const residing_Id = "";
            const baseURL = apiConst.getAccountspeciality;
            const url = residing_Id ? `${baseURL}?sid=${residing_Id}` : baseURL;
            serverGet(url).then((res) => {
                if (res?.status == 1 && res?.data) {
                    const _selected = res?.data?.find((list) => list?.id == _specialityId);
                    if (_selected) {
                        setSpeciality(_selected);
                    }

                }
            })
        }
    }


    //console.log(speciality)
    useEffect(() => {
        const pharmacy_id = getSearchParams('phaid')
        const _summary = {
            timeSlot: getSearchParams('ts'),
            reason: getSearchParams('r'),
            pharmacy: getSearchParams('p'),
            specialityId: getSearchParams('spid'),
            //price: doctorContext?.minimumPrice?.price?.toString() || getSearchParams('minP') || "",
            price: schedulePricing,
            encModeId: getSearchParams("emid"),
            encDurationId: getSearchParams("did"),
            reasonCode: getSearchParams("reascode"),
            withoutProvider: getSearchParams("brtype") == appConst.resourceCodes.sharedCalender || getSearchParams("brtype") == appConst.resourceCodes.consultNowWithoutProvider,
            providerMemberId: getSearchParams("memberid") || null,
            scheduleNumber: getSearchParams("snum"),
            bookingType: getSearchParams("brtype")
        }
        setSummary(_summary);

        if (pharmacy_id && pharmacy_id !== "") {
            if (pharmacy_id != pharmacyId) {
                setPharmacyId(pharmacy_id);
                getPharmacy(pharmacy_id);
            }
        }
        if (_summary?.price) {
            updateSearchParams("minP", _summary?.price);
        }

        if (!speciality || _summary?.specialityId != getSearchParams('spid')) {
            getSpeciality();
        }

        //return (() => {
        //    setSummary(null);
        //})
    }, [location, doctorContext, schedulePricing])


    return (
        <div className="bg-white mb-3 mb-md-0 me-md-3">
            <div className="card border-0 wf-left">
                <div className="card-body">
                    <div className="d-flex wf-left-header">
                        <>
                            {(summary?.withoutProvider && (!doctorContext || !summary?.providerMemberId)) ? (
                                <>
                                    {!doctorContextLoader && (
                                        <div className="d-flex">
                                            <div>
                                                <LazyImageComponent
                                                    alt="profile-pic"
                                                    className="rounded"
                                                    src={require('../../../../asset/images/blankuser.png')}
                                                    width="70"
                                                />
                                            </div>
                                            <div className="ms-3 flex-grow-1 position-relative">
                                                <h5>{getLanguageContent("prvdpna")}</h5>
                                                <div className="my-2 truncate-text">
                                                    <span>{speciality?.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!doctorContextLoader && (
                                        <>
                                            <div>
                                                <LazyImageComponent
                                                    alt="profile-pic"
                                                    className="rounded"
                                                    src={!doctorContext?.member?.photoPath ? require('../../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(doctorContext?.member?.photoPath)}
                                                    width="70"
                                                />
                                            </div>
                                            <div className="ms-3 flex-grow-1 position-relative">
                                                {doctorContext?.member?.firstName &&
                                                    <a className="fs-5 fw-semibold" onClick={providerprofile}>{doctorContext?.member?.firstName} {doctorContext?.member?.lastName}</a>
                                                }

                                                {/*<div className="my-2 truncate-text">{doctorContext?.specialities?.length > 0 && doctorContext?.specialities?.filter((list) => list?.specialityId == summary?.specialityId).map((speciality, i) => (*/}
                                                {/*    <span key={`child${i}`}>{speciality?.name}</span>*/}
                                                {/*))}*/}
                                                {/*</div>*/}
                                                <div className="my-2 truncate-text">
                                                    <span>{speciality?.name}</span>
                                                </div>
                                                <div className={`position-absolute top-0 end-0 ${location.pathname === "/payment" ? "d-none" : "d-block d-md-none"}`}>
                                                    <button class="btn btn-light" type="button" aria-expanded={!isLeftCollapsed ? true : false} onClick={() => setLeftCollapsed(!isLeftCollapsed)}>
                                                        <svg width="12" height="7"><use xlinkHref="#svg_arrowdown"></use> </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </div>
                    <hr></hr>
                    {(showAccPricing && AccountCurrency?.value && summary?.price && parseFloat(summary?.price) > 0 && isEnabled(accountSettings, "PFS", 1) && !doctorContextLoader) ?
                        <div className="pb-3 position-relative">
                            <span>Payment</span>
                            <span className="fs-5 text-secondary position-absolute end-0">{AccountCurrency?.value}<span className="fs-5 text-dark fw-semibold">{parseFloat(summary?.price)?.toFixed(2)}</span></span>
                        </div> : ""
                    }
                    {/*<div className="small card-subtitle mt-2 mb-3 text-body-secondary"> need to check overall setting*/}
                    {/*    <span class="fa fa-star text-warning checked"></span> <span>4.5</span>  <span className="fa fa-comment mx-2"></span> 2 Reviews*/}
                    {/*</div>*/}
                    <div className={`wf-left-content ${isLeftCollapsed || location.pathname == "/payment" ? "open" : ""}`}>
                        {(profileContext?.resProfile?.firstName && !getSearchParams("fmid")) && (
                            <>
                                <div className="py-3">
                                    <div>
                                        <svg className="me-2 " width="15" height="15" role="img"><use xlinkHref="#svg_user" /></svg>
                                        {getLanguageContent("consltfr")}
                                    </div>
                                    <span><span className="fw-semibold">{`${profileContext?.resProfile?.firstName ? profileContext?.resProfile?.firstName : ''} ${profileContext?.resProfile?.lastName ? profileContext?.resProfile?.lastName : ''}`}</span> (Myself)</span>
                                </div>
                            </>
                        )}
                        {(getSearchParams("fmid") && contextFamilyList && contextFamilyList?.find((list) => list?.id == getSearchParams("fmid"))) && (
                            <>
                                <div className="py-3">
                                    <div>
                                        <svg className="me-2 " width="15" height="15" role="img"><use xlinkHref="#svg_user" /></svg>
                                        {getLanguageContent("consltfr")}
                                    </div>
                                    <span><span className="fw-semibold">{`${findFamilyMember(getSearchParams("fmid"))?.firstName} ${findFamilyMember(getSearchParams("fmid"))?.lastName}`}</span> ({findFamilyMember(getSearchParams("fmid"))?.relationship})</span>
                                </div>
                            </>
                        )}
                        {location.pathname !== "/booking" && (
                            <div className="py-3">
                                <div>
                                    <svg className="me-2 " width="17" height="17" role="img"><use xlinkHref="#calender_sch" /></svg>
                                    Appointment {(summary?.encModeId && cateogrySettings) && <span className="fw-normal" >({cateogrySettings?.find((list) => list?.id == summary?.encModeId && list?.group == "encounter_mode")?.name})</span>}
                                </div>
                                <span className="fw-semibold">
                                    {summary?.timeSlot && `${timeFormat(summary?.timeSlot, accountDate?.value)} ${summary?.timeSlot && timeFormat(summary?.timeSlot)}`}
                                    {(summary?.encDurationId && cateogrySettings && summary?.bookingType != "GT6") && <span> | ({cateogrySettings?.find((list) => list?.id == summary?.encDurationId && list?.group == "schedule_duration")?.name})</span>}
                                </span>
                            </div>
                        )}
                        {(summary?.scheduleNumber && location.pathname !== "/booking" && cateogrySettings?.some((list) => list?.id == summary?.encModeId && list?.group == "encounter_mode" && (list?.code == "PHT" || list?.code == "PHN"))) && (
                            <div className="py-3">
                                <div>
                                    <svg className="me-2 " width="17" height="17" role="img"><use xlinkHref="#svg_phn_consult" /></svg>
                                    {getLanguageContent("phonum")}
                                </div>
                                <span className="fw-semibold">
                                    {summary?.scheduleNumber}
                                </span>
                            </div>
                        )}
                        {((location.pathname !== '/reason' && summary?.reason) && (summary?.reasonCode && summary?.reasonCode?.trim() != "OTH")) && (<>
                            <div className="py-3">
                                <div>
                                    <svg className="me-2 " width="17" height="17" role="img"><use xlinkHref="#reason_sch" /></svg>
                                    {summary?.reason && <span>{getLanguageContent("reson")}</span>}
                                </div>
                                <span className="fw-semibold">{summary?.reason}</span>
                            </div>
                        </>
                        )}
                        {(pharmacyId != "00000000-0000-0000-0000-000000000000" && location.pathname !== '/pharmacy' && location.pathname !== '/reason' && pharmacy) && (
                            <>
                                <div className="py-3">
                                    <div>
                                        <svg className="me-2" width="17" height="17" role="img"><use xlinkHref="#pharmacy_sch" /></svg>
                                        {pharmacy && <span>{getLanguageContent("phrmcy")}</span>}
                                    </div>
                                    <span className="fw-semibold">{pharmacy?.name}</span>
                                    {/*    <span className="fw-semibold">{pharmacy?.name}{pharmacy?.address1 ? `, ${pharmacy?.address1}` : ""}{pharmacy?.address2 ? `, ${pharmacy?.address2}` : ""}{pharmacy?.city ? `, ${pharmacy?.city}` : ""}{pharmacy?.state ? `, ${pharmacy?.state}` : ""}{pharmacy?.zipcode ? `, ${pharmacy?.zipcode}` : ""}</span>*/}
                                    <br /><small>{pharmacy?.address1 ? `${pharmacy?.address1}` : ""}{pharmacy?.address2 ? `, ${pharmacy?.address2}` : ""}{pharmacy?.city ? `, ${pharmacy?.city}` : ""}{pharmacy?.state ? `, ${pharmacy?.state}` : ""}{pharmacy?.zipcode ? `, ${pharmacy?.zipcode}` : ""}</small>
                                </div>
                            </>
                        )}
                        {(pharmacyId == "00000000-0000-0000-0000-000000000000" && location.pathname !== '/pharmacy') && (
                            <>
                                <div className="py-3">
                                    <div>
                                        <svg className="me-2" width="17" height="17" role="img"><use xlinkHref="#pharmacy_sch" /></svg>
                                        <span>{getLanguageContent("phrmcy")}</span>
                                    </div>
                                    <span className="fw-semibold">{getLanguageContent("phseukn")}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}