import { createContext, useEffect, useState } from "react";

const ProgressContext = createContext(null)

export default ProgressContext

export function ProgressContextProvider({ children }) {

    const [option, setOption] = useState(false);
 
    const showProg = (value) => {
        setOption(value);
    }


    const showLoaderProgress = () => {
        setTimeout(() => {
            setOption(true);
        },0)
    }
    const hideLoaderProgress = () => {
        setTimeout(() => {
            setOption(false);
        }, 2)
    }
    //useEffect(() => {
    //    setOption(option);
      
    //}, [option])

    return (
        <ProgressContext.Provider value={{ progParams: showProg, showLoaderProgress: showLoaderProgress, hideLoaderProgress: hideLoaderProgress }}>
            {children}
            {
                option == true ?
                    <div className="modal-backdrop fade show">
                        <div className="modal" style={{ display: "block" }} >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content" style={{
                                    backgroundColor: "transparent",
                                    color: "#fff"
                                }}>
                                    <div className="modal-body text-center">
                                        <div className="spinner-border" style={{ width: "4rem", height: "4rem" }} role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div>Loading...</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <></>
            }
        </ProgressContext.Provider>
    );
}

