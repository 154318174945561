import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { apiConst } from '../../../services/constant/constant';
import { serverGet, serverFormPost } from '../../../middleware/http'
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Signup from "./SignUp";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import AccountActivation from "./AccountActivation";
import ProgressContext from "../../../services/utils/progress";
import { getSearchParams } from '../../../services/utils/validation';

const PubSecurityLayout = (props) => {

    const clientId = getSearchParams("client_id");
    const clientSecret = getSearchParams("client_secret");
    const scopes = getSearchParams("scope");
    const redirect_uri = getSearchParams("redirect_uri");
    const response_type = getSearchParams("response_type");
    const to_redirect_uri = getSearchParams("to_redirect_uri");
    const [authChecking, setauthChecking] = useState(null);
    const [isLoginPage, setIsLoginPage] = useState("null");
    const { progParams } = useContext(ProgressContext);
    const { isGWF, account } = useAccountSettings();
    const location = useLocation();


    useEffect(() => {

        if (account) {
            if (isGWF) {
                setIsLoginPage(false);
            } else {
                if (location.pathname.includes("/security/login") && !isGWF) {
                    setIsLoginPage(true);
                } else {
                    setIsLoginPage(false);
                }
            }
        }

        setauthChecking(false);
    }, [isGWF, account, location])

   

    return (
        <>{authChecking != null ?
            <div className={`${isLoginPage && isLoginPage != "null" ? "" : "card border-0"}`}>
                <div className={`${isLoginPage && isLoginPage != "null" ? "" : "card-body p-2 p-md-4"}`}>
                    <div className="row align-self-center justify-content-center">
                        {isGWF &&
                            <div className="col-md-5 col-lg-6 align-self-center my-4 d-none d-md-block">
                                <img className="img-fluid" src={require('../../../asset/images/gayawellness-banner.jpg')} alt="banner_img" />
                            </div>
                        }

                        <div className={`col-md-7 col-lg-${isLoginPage && isLoginPage != "null" ? 5 : 6}`}>
                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "78.5vh" }}>
                                <div className={`${isLoginPage && isLoginPage != "null" ? "card border-0" : ""} w-100`}>
                                    <div className={`${isLoginPage && isLoginPage != "null" ? "card-body p-2 p-md-4" : ""}`}>
                                        {isLoginPage && (
                                            <div className="d-none d-md-block text-center mb-3 align-self-center">
                                                {account?.logo ?
                                                    <img src={'/doc/getimage?url=' + encodeURIComponent(account?.logo)} height="60" alt="logo" />
                                                    : <h4 className="text-secondary">{account?.name}</h4>
                                                }
                                            </div>
                                        )}
                                        <div className="flex-fill">
                                            {(() => {
                                                switch (props.body) {
                                                    case 'forgotpassword': return (<ForgotPassword />)
                                                    case 'resetpassword': return (<ResetPassword />)
                                                    case 'signup': return (<Signup />)
                                                    case 'accountactivation': return (<AccountActivation />)
                                                    default: return (<Login />)
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <></>
        }
        </>
    );
}

export default PubSecurityLayout;