import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import he from "he";
import { serverGet } from '../../middleware/http';
import { apiConst } from '../../services/constant/constant';
import { useAccountSettings } from '../../services/Context/AccSettingContext';
import { useProfile } from '../../services/utils/profileContext';
import ProgressContext from '../../services/utils/progress';
import { ecnSession, getAuthProfile, isEnabled } from '../../services/utils/validation';

const Home = () => {
    const [content, setContent] = useState(null);
    const [homePage, setHomePage] = useState(null);
    const [specialityList, setSpecialityList] = useState([]);
    const navigate = useNavigate();
    const { accountSettings, account } = useAccountSettings();
    const { patientInfo } = useProfile();
    const { progParams } = useContext(ProgressContext);


    const getList = () => {
        serverGet(apiConst.getproviderspeciality).then((res) => {
            try {
                if (res?.data)
                    setSpecialityList(res?.data);
            } catch (e) {
                console.log(e);
            }
        })
    }

    const getStarted = async () => {

        try {
            progParams(true);
            const residingStateRes = await serverGet(`${apiConst.getlicenseregion}`);
            progParams(false);
            const stateRes = residingStateRes?.status == 1 && residingStateRes?.data?.states?.length > 0;
            const isResidingState = isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1);
            const residingId = isResidingState ? `rsid=${patientInfo?.residingStateId}` : ""
            //&& patientInfo?.residingStateId
            //&& !patientInfo?.residingStateId
            if (isEnabled(accountSettings, "BNWTP", 1)) {
                if (isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1) && getAuthProfile.getProfileAuth() && stateRes) { // checks for is residing state & location based services is enabled
                    navigate(`/residingstate?id=${specialityList?.length == 1 ? specialityList[0]?.id : "y"}${residingId ? `&${residingId}` : ""}`); //checks speciality length
                } else if (isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1) && !getAuthProfile.getProfileAuth() && stateRes) {
                    navigate(`/residingstate?id=${specialityList?.length == 1 ? specialityList[0]?.id : "y"}${residingId ? `&${residingId}` : ""}`); //checks speciality length
                } else if (isEnabled(accountSettings, "ENSP", 1)) {
                    if (specialityList?.length == 1) {
                        const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-${residingId ? `&${residingId}` : ""}`);
                        if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                            return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}${residingId ? `&${residingId}` : ""}&minP=${ProviderRes?.data[0]?.minimumPrice}&sinp=t`);
                        }
                        navigate(`/provider?spid=${specialityList[0]?.id}${residingId ? `&${residingId}` : ""}`);
                    } else {
                        navigate(`/specialty${residingId ? `?${residingId}` : ""}`);
                    }
                } else {
                    if (specialityList?.length == 1) {
                        const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-${residingId ? `&${residingId}` : ""}`);
                        if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                            return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}${residingId ? `&${residingId}` : ""}&minP=${ProviderRes?.data[0]?.minimumPrice}&sinp=t`);
                        }
                    }
                    navigate(`/provider${residingId ? `?${residingId}` : ""}`);
                }
            } else {
                navigate("/dashboard");
            }
            ecnSession("clear");
        } catch (e) {
            console.error(e);
        }
    }

    const getContent = () => {
        progParams(true);
        serverGet(`${apiConst.getContent}en-us?code=${"C01"}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setContent(res?.data);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }


    useEffect(() => {
        document.title = "Homepage";

        const fetchData = async () => {
            try {
                await getList();
                await getContent();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {

        if (Array.isArray(account?.portalSettings)) {
            const data = account?.portalSettings?.find((list) => list?.code == "HPC");
            if (data) {
                const cleanHtml = DOMPurify.sanitize(data?.value);
                const decodedHTML = he.decode(cleanHtml);
                //console.log(cleanHtml);
                setHomePage(decodedHTML)
            }
        }

    }, [account])


    return (
        <>
            <div className="d-flex justify-content-center align-items-center home-container">
                <div className="row justify-content-center">
                    <div className="col-sm-9 col-md-8 col-lg-10 text-center w-100 my-4">
                        {(content?.content && accountSettings?.length > 0) && (
                            <div
                                dangerouslySetInnerHTML={{ __html: homePage }}
                            />
                        )}
                    {/*    {(accountSettings && content?.content) && <button onClick={getStarted} className="btn btn-primary btn-lg">Get Started</button>}*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;