import { checkIfValidUUID } from '../utils/validation';


const setting = {
    optionsByCode: function (data, code, displaytext) {
        if (data != undefined) {
            var options = data.filter(obj => obj.settingCode === code && obj.displayText == displaytext);
            if (options.length == 0)
                return null;
            else
                return data.filter(obj => obj.settingCode === code && obj.displayText == displaytext)[0].settingOptionId;
        }
    },
    valueNode:(data, code, objectkey= '', datatype = 'string') => {
        let dataindex = 0;
        if (data != undefined && data.length > 0) {
            dataindex = data.findIndex(obj => obj.settingCode == code);
            if ((datatype == undefined ? '' : datatype) == 'bool')
                data[dataindex].value = data[dataindex].value == '1' ? 1 : 0;
        }

        return objectkey + "[" + dataindex + "].value";
    },
    nodeIndex: (data, code) => {
        let dataindex = 0;
        if (data != undefined && data.length > 0) {
            dataindex = data.findIndex(obj => obj.settingCode == code);
        }
        return dataindex;
    },
    nodeIndex: (data, code) => {
        let dataindex = 0;
        if (data != undefined && data.length > 0) {
            dataindex = data.findIndex(obj => obj.settingCode == code);
        }
        return dataindex;
    },
    filterDataByCode: (data, code) => {
        if (data != undefined && data?.length > 0)
            return data?.filter(obj => obj?.settingCode === code);
    },
    setSwitchOptionId: (options, data, codes) => {
        codes.forEach((code) => {
            var stng = data.filter(obj => obj.settingCode === code);
            if (stng != null) {
                stng[0].value = stng[0].value == true ? "1" : "0";
                var option = options.filter(obj => obj.settingCode === code && obj.displayText === (stng[0].value == "1" ? "Yes" : "No"))[0];
                stng[0].settingOptionId = option.settingOptionId;
            }
        })
    },
    setOptionIds: (data, codes) => {
        codes.forEach((code) => {
            var stng = data.filter(obj => obj.settingCode === code);
            if (stng != null) {
                stng[0].value = stng[0].value.toString();
            }
        })
    },
    setValuetoOptionId: (data, codes) => {
        codes.forEach((code) => {
            var stng = data.filter(obj => obj.settingCode === code);
            if (stng != null) {
                if (!checkIfValidUUID(stng[0].value.split(",")[0])) {
                    stng[0].value = stng[0].settingOptionId;
                    stng[0].settingOptionId = null;
                }
            }
        })
    },
    filterAndSetValue: (data, key, value, newvalue) => {
        var result = data.filter(obj => obj[key] === value);
        result.forEach(item => {
            item[key] = newvalue;
        });
    },
    filterCode: (data, actionid) => {
        var result = data.filter(obj => obj.actionId === actionid);
        if (result.length > 0) {
            return result[0].id;
        } else {
            return "";
        }
    },
    filterValue: (data, actionid) => {
        var result = data.filter(obj => obj.actionId === actionid);
        if (result.length > 0) {
            return result[0].moduleFeatureId + "|" + actionid;
        } else {
            return "";
        }
    },
    filterCodeDisabled: (data, actionid) => {
        var result = data.filter(obj => obj.actionId === actionid);
        if (result.length > 0) {
            return false;
        } else {
            return true;
        }
    },
    getGender: (genderid) => {
        switch (genderid) {
            case 1:
                return 'Male'
            case 2:
                return 'Female'
            case 3:
                return 'Other'
            default:
                return ''
        }
    }
}
export default setting

 





