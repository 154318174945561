import React, { useState, useEffect } from "react";
import DoctorSummary from "../../../consultation/summary/DoctorSummary";
import ScheduleHeader from "../../../consultation/summary/ScheduleHeader";
import Forms from "../forms";
import { appConst } from "../../../../../services/constant/constant";
import { getSearchParams } from "../../../../../services/utils/validation";


export default function IntakeForms() {

    const [isExternal, setIsExternal] = useState(false);

    useEffect(() => {

        const _isExternal = getSearchParams("isexternal");

        if (_isExternal) setIsExternal(_isExternal);

    }, [])

    return (
        <>
            <div className="px-2 px-md-0">
                {!isExternal && <ScheduleHeader title={"Intake forms"} show={isExternal ? "No" : ""} />}
                <div className="d-flex flex-md-row flex-column">
                    {!isExternal && (
                        <DoctorSummary />
                    )}
                    <div className="flex-grow-1">
                        <Forms level="intake-flow" formShowState={true} areaId={appConst.areaId.intakeForms} isExternal={isExternal} />
                    </div>
                </div>
            </div>
        </>
    )
}