import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import ScheduleHeader from "../consultation/summary/ScheduleHeader";
import { useProfile } from "../../../services/utils/profileContext";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import { getMemberId, getSearchParams } from "../../../services/utils/validation";
import { serverGet } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";

export default function MemberPlanDetail() {

    const navigate = useNavigate();
    const { patientInfo } = useProfile();
    const { getLanguageContent } = useLanguageRes();
    const {account } = useAccountSettings();
    //console.log(account);
    //const [planCodes, setPlanCode] = useState([{ name: "Behavioral Health", code: "P2, P3, P6, P7, P8, P9, P10", view: false }, { name: "Care Coordination", code: "P3, P4, P6, P7, P8, P9, P10", view: false }, { name: "Medical Specialist", code: "P5, P6, P7, P8, P9, P10", view: false }])


    const getPlanList = async (patient) => {
        try {
            let _memberId;

            if (patient?.parentId) {
                _memberId = patient?.parentId
            } else {
                _memberId = await getMemberId();
            }

            const res = await serverGet(`${apiConst.getPlan}${_memberId}`)

            if (res?.data && res?.status == 1) {
                getPlanCode(res?.data?.planId)
            } else {
                return navigate("/pagenotfound");
            }
        } catch (e) {
            console.log(e)
        }

    }


    const getPlanCode = (id) => {
        serverGet(`${apiConst.getPlanCode}${id}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data) {
                    const code = res?.data?.code;
                    const _code = getSearchParams("p"); // link
                    if (code?.toLowerCase() != _code?.toLowerCase()) {
                        return navigate("/pagenotfound");
                    }
                } else {
                    return navigate("/pagenotfound");

                }
            } catch (e) {
                console.log(e)
            }
        })
    }

    useEffect(() => {
        document.title = "Plan information";
    }, [])
  //  const text = `text{account_code} text {account_code} `


    useEffect(() => {
        if (patientInfo) {
            getPlanList(patientInfo);
        }
    }, [patientInfo])

    return (
        <>
            {(patientInfo && getLanguageContent(getSearchParams("c"))) && (

                <>
                    {getLanguageContent(getSearchParams("t")) && (
                        <div className="px-2 px-md-0">
                            <ScheduleHeader title={getLanguageContent(getSearchParams("t"))} btd={true} />
                        </div>
                    )}

                    <div className="card border-0 p-2 mt-3">
                        <div className="card-body">
                            <div dangerouslySetInnerHTML={{ __html: getLanguageContent(getSearchParams("c")).replace(/{account_code}/g, account?.code?.toLowerCase())}}>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

