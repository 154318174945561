import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import { subYears, addDays } from "date-fns";
import { serverGet, serverPost, serverPut } from '../../../middleware/http';
import { apiConst, appConst } from '../../../services/constant/constant';
import { getMsg } from '../../../services/utils/validation';
import ProgressContext from '../../../services/utils/progress';
import NotifyContext from '../../../services/utils/notify';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import Moment from 'moment';


export default function InsuranceForm(props) {
    const [autoCompleteloading, setAutoCompleteloading] = useState(false);
    const [insuranceList, setInsuranceList] = useState([]);
    const [insDoc, setInsDoc] = useState([]);
    const [insurance, setInsurance] = useState([]);
    const [selectedInsurance, setSelectedInsurance] = useState(null);
    const [viewIns, setViewIns] = useState(false)
    const [cateogry, setCateogry] = useState([]);
    const [subscriber, setSubscriber] = useState("yes");
    const [expiryDate, setExpiryDate] = useState();
    const [selectedDob, setSelectedDob] = useState();
    const [insuranceNote, setInsuranceNote] = useState("");
    const { notifyToast } = useContext(NotifyContext)
    const { progParams } = useContext(ProgressContext);
    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const { getLanguageContent } = useLanguageRes();
    const { accountDate } = useAccountSettings();

    const onSubmit = (data) => {
        data.insuranceId = insurance[0]?.id;
        const member_id = localStorage.getItem("memberId");

        //console.log(data);
        const formadata = {
            groupNo: data?.groupNo,
            insuranceId: insurance[0]?.id,
            isSubscriber: data?.isSubscriber == "yes" ? true : false,
            planCode: data?.planCode,
            planName: data?.planName,
            policyNo: data?.policyNo,
            memberId: member_id,
            statusId: data?.statusId,
            insuredName: ""
        }

        if (data?.expiryDate) {
            formadata.expiryDate = Moment(data?.expiryDate).format('YYYY-MM-DD');
        }

        if (subscriber == "no") {
            formadata.insuredDOB = Moment(data?.insuredDOB).format('YYYY-MM-DD');
            formadata.insuredName = data?.insuredName;
            formadata.relationshipId = data?.relationshipId;
        }



        //console.log(formadata);
        if (props?.insuranceId) {
            if (insuranceNote)
                formadata.note = insuranceNote;
            updateInsurance(formadata);
        } else {
            createInsurance(formadata);
        }

    }

    const createInsurance = (data) => {
        progParams(true);
        serverPost(apiConst.addinsurance, data).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(res.messageCode[0]);
                    props?.toggleIns();
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })


    }

    const updateInsurance = (data) => {

        progParams(true);
        serverPut(apiConst.updateinsurance + props?.insuranceId, data).then((res) => {
            progParams(false);
            try {
                if (res?.data && res?.status == 1) {
                    props?.toggleIns();
                    props?.setInsuranceId(null);
                } else {
                    var error = JSON.parse(res.data.errorMessage);
                    notifyToast(error.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const getinsurancelist = (data) => {
        setAutoCompleteloading(true);
        serverGet(apiConst.autocompleteinsurance + data).then((res) => {
            setAutoCompleteloading(false);
            try {
                if (res?.data != null && res?.status == 1) {
                    setInsuranceList(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const viewInsurance = (id) => {
        progParams(true);
        serverGet(apiConst.editinsurance + id).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1 && res?.data) {
                    const response = res?.data;
                    setSelectedInsurance(response);

                    setInsurance([{
                        id: response?.insuranceId,
                        name: response?.insuranceName
                    }])
                    setValue('statusId', response?.statusId);
                    setValue('insuranceId', response?.insuranceId);
                    if (response?.insuredName) setValue('insuredName', response?.insuredName);
                    if (response?.insuredDOB) setValue('insuredDOB', new Date(response?.insuredDOB));
                    if (response?.isSubscriber) {
                        setValue('isSubscriber', "yes");
                        setSubscriber("yes");
                    } else {
                        setValue('isSubscriber', "no");
                        setSubscriber("no");
                    }
                    setValue('policyNo', response?.policyNo);
                    setValue('groupNo', response?.groupNo);
                    setValue('planName', response?.planName);
                    setValue('planCode', response?.planCode);
                    if (response?.expiryDate)
                        setValue('expiryDate', new Date(response?.expiryDate));
                    //setValue('subscriber', "2");
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const getCateogry = () => {
        progParams(true);
        serverGet(`${apiConst.getcateogry}${appConst.cateogries.relationship}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setCateogry(res.data);

                }
            } catch (e) {
                console.log(e)
            }
        })
    }

    const handleChange = (e) => {
        setSubscriber(e.target.value);
    }


    const handleBack = (e) => {
        e.preventDefault();
        props?.toggleIns();
    }

    useEffect(() => {
        getCateogry();
        if (props?.insuranceId) {
            setViewIns(true);
            viewInsurance(props?.insuranceId);
        }
    }, []); 



    useEffect(() => {

        if (props?.insuranceId && selectedInsurance) {
            if (selectedInsurance?.relationshipId) setValue('relationshipId', selectedInsurance?.relationshipId);
            setInsuranceNote(selectedInsurance?.note);

        }
    }, [selectedInsurance, viewIns,cateogry]);


    //console.log(selectedInsurance)
    const filterBy = () => true;
    //const tomorrow = new Date();
    //tomorrow.setDate(tomorrow.getDate() + 1);
    //setValue('expiryDate', new Date(tomorrow));
    let today = new Date();
    let tomorrow = new Date(today);
    let min = tomorrow.setDate(today.getDate() + 1);
    let max = tomorrow.setFullYear(today.getFullYear() + 100);  
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row p-3 p-sm-2">
                    <div className="mb-3">
                        <span className="me-1">{getLanguageContent("patsubscrbr")}</span>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="yes">Yes</label>
                            <input className="form-check-input" type="radio" name="subscriber" id="yes" value={"yes"} checked={subscriber == "yes"}
                                {...register("isSubscriber", {
                                    required: getMsg(301),
                                    onChange: (e) => handleChange(e)
                                })} />
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="no">{getLanguageContent("no")}</label>
                            <input onChange={(e) => handleChange(e)} className="form-check-input" type="radio" name="subscriber" id="no" value={"no"} checked={subscriber == "no"}
                                {...register("isSubscriber", {
                                    required: getMsg(301),
                                    onChange: (e) => handleChange(e)

                                })} />
                        </div>
                        <br />
                        {errors?.isSubscriber?.message && <span className="text-danger">{errors?.isSubscriber?.message}</span>}
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <Controller
                                control={control}
                                name="insuranceId"
                                rules={{
                                    required: getMsg(301),
                                    //maxLength: { value: 50, message: getMsg("305") }
                                }}
                                render={({ field, fieldState }) => (
                                    <div className="mb-3">
                                        <label htmlFor="insuranceId" className="form-label">{getLanguageContent("insrncename")}<span className="text-danger star">*</span></label>
                                        <AsyncTypeahead  {...field}
                                            id="insuranceId"
                                            filterBy={filterBy}
                                            isLoading={autoCompleteloading}
                                            labelKey="name"
                                            autoFocus={!viewIns}
                                            onSearch={(query) => getinsurancelist(query)}
                                            selected={insurance}
                                            onChange={(selected) => {
                                                field.onChange(selected);
                                                setInsurance(selected)
                                                setValue('insuranceId', selected[0]?.id);
                                            }}
                                            options={insuranceList}
                                            name="insuranceId"
                                            placeholder={getLanguageContent("entinsname")}
                                            className={fieldState.invalid ? "is-invalid" : ""}
                                            aria-describedby="typeaheadError"
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                    {option.name}
                                                </div>
                                            )}
                                        />
                                        {errors?.insuranceId?.message && <p className="text-danger">{errors?.insuranceId?.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label htmlFor="policyNo" className="form-label">{getLanguageContent("plcyno")}<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="policyNo" placeholder={getLanguageContent("entplyno")} {...register("policyNo", { required: getMsg(301), maxLength: { value: 15, message: getMsg("305") } })} />
                            {errors?.policyNo?.message && <p className="text-danger">{errors?.policyNo?.message}</p>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label htmlFor="planName" className="form-label">{getLanguageContent("plnname")}<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="planName" placeholder="Enter plan name" {...register("planName", { required: getMsg(301), maxLength: { value: 100, message: getMsg("305") } })} />
                            {errors?.planName?.message && <p className="text-danger">{errors?.planName?.message}</p>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label htmlFor="planCode" className="form-label">{getLanguageContent("plncode")}</label>
                            <input type="text" className="form-control" id="planCode" placeholder="Enter plan code" {...register("planCode", { maxLength: { value: 10, message: getMsg("305") } })} />
                            {errors?.planCode?.message && <p className="text-danger">{errors?.planCode?.message}</p>}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label htmlFor="groupNo" className="form-label">{getLanguageContent("grpno")}</label>
                            <input type="text" className="form-control" id="groupNo" placeholder="Enter group number" {...register("groupNo", { maxLength: { value: 10, message: getMsg("305") } })} />
                            {errors?.groupNo?.message && <p className="text-danger">{errors?.groupNo?.message}</p>}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="Expiry date">{getLanguageContent("exprydt")}</label>
                            <Controller
                                control={control}
                                name="expiryDate"
                                //rules={{
                                //    required: getMsg(301)
                                //}}
                                render={({ field }) => (
                                    <DatePicker
                                        id='expiryDate'
                                        name="expiryDate"
                                        selected={field?.value}
                                        dateFormat={accountDate?.value ? accountDate?.value?.toLowerCase()?.replace(/mm/, 'MM') : 'MM/dd/yyyy'}
                                        className="form-control"
                                        onChange={(date) => {
                                            field.onChange(date)
                                            setExpiryDate(date);
                                        }}
                                        placeholderText='Enter expiry date'
                                        showMonthDropdown
                                        showYearDropdown
                                        wrapperClassName="w-100"
                                        dropdownMode="select"
                                        minDate={min}
                                        maxDate={max}
                                    />
                                )}
                            />
                            {(errors?.expiryDate?.message && !expiryDate) && <p className="text-danger">{errors?.expiryDate?.message}</p>}
                        </div>
                    </div>
                    {viewIns &&
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="statusId" className="form-label">{getLanguageContent("stats")}<span className="text-danger star">*</span></label>
                                <select type="text" className="form-control form-select" id="statusId" placeholder={getLanguageContent("stats")} {...register("statusId", { required: getMsg(301) })}>
                                    <option value="">{getLanguageContent("select")}</option>
                                    <option value='1'>Active</option>
                                    <option value='2'>Inactive</option>
                                </select>
                                {errors?.statusId?.message && <p className="text-danger">{errors?.statusId?.message}</p>}
                            </div>
                        </div>
                    }
                    {subscriber == "no" &&
                        <>
                            <div className="col-sm-6">
                                <div className="mb-3">
                                    <label htmlFor="insuredName" className="form-label">{getLanguageContent("subname")}<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="insuredName" placeholder={getLanguageContent("entsubnme")} {...register("insuredName", { required: getMsg(301), maxLength: { value: 100, message: getMsg("305") } })} />
                                    {errors?.insuredName?.message && <p className="text-danger">{errors?.insuredName?.message}</p>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="insuredDOB">{getLanguageContent("subdob")}<span className="text-danger star">*</span></label>
                                    <Controller
                                        control={control}
                                        name="insuredDOB"
                                        rules={{
                                            required: getMsg(301)
                                        }}
                                        render={({ field }) => (
                                            <DatePicker
                                                selected={field.value}
                                                dateFormat={accountDate?.value ? accountDate?.value?.toLowerCase()?.replace(/mm/, 'MM') : 'MM/dd/yyyy'}
                                                className="form-control"
                                                onChange={(date) => {
                                                    field.onChange(date)
                                                    setSelectedDob(date);
                                                }}
                                                placeholderText={getLanguageContent("entsubdob")}
                                                showMonthDropdown
                                                showYearDropdown
                                                wrapperClassName='w-100'
                                                dropdownMode="select"
                                                maxDate={subYears(new Date(), 18)}
                                            />
                                        )}
                                    />
                                    {(errors?.insuredDOB?.message && !selectedDob) && <p className="text-danger">{errors?.insuredDOB?.message}</p>}
                                </div>
                            </div>
                            <div className="mb-3 col-sm-6">
                                <label htmlFor="RelationshipId" className="form-label">{getLanguageContent("relationsub")}<span className="text-danger star">*</span></label>
                                <select className="form-control form-select" id="RelationshipId"  {...register("relationshipId", { required: getMsg(301) })}>
                                    <option value="">{getLanguageContent("select")}</option>
                                    {cateogry?.length > 0 && cateogry.sort((a, b) => (a?.name > b?.name ? 1 : -1))?.map((list, i) => (
                                        <option key={i} value={list?.id}>{list?.name}</option>
                                    ))}
                                </select>
                                {errors.relationshipId?.message && <span className="text-danger">{errors.relationshipId?.message}</span>}
                            </div>

                        </>
                    }
                    {/*<div>*/}
                    {/*    <label className="form-label">Documents</label>*/}
                    {/*    <DocumentUpload size={10}*/}
                    {/*        setDocState={setInsDoc}*/}
                    {/*        encounter={false}*/}
                    {/*        fileTypes={appConst.fileTypes.reason.validationTypes}*/}
                    {/*        disText={appConst.fileTypes.reason.displayText}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="mb-3 text-end">
                        <button className="btn btn-lg btn-secondary me-2" onClick={(e) => handleBack(e)}>{getLanguageContent("bck")}</button>


                        {props?.insuranceFlow || (!props?.insuranceFlow && props?.isEdit && props?.insuranceId) || (!props?.insuranceFlow && props?.isCreate && !props?.insuranceId) ? (
                            <button className="btn btn-lg btn-primary" type="submit">{getLanguageContent("sve")}</button>
                        ) : ""}
                    </div>
                </div>
            </form>
        </>
    )
}



