import { Link } from "react-router-dom";

const ActionStatus = (props) => {
    return (
        <>
            {
                props != undefined ?
                    <>
                        {
                            props.type == "search" ?
                                <div className="text-center my-2">
                                    {props.description &&
                                        <p className={`p-0 m-0 ${props?.descriptionClass ? props?.descriptionClass : "fw-bold"}`}>{props.description}</p>
                                    }

                                    {props?.showSvg && (
                                        <svg fill={props?.fill ? props?.fill : ""} width={props?.width ? props?.width : "150"} height={props?.height ? props?.height : "150"}  role="img"><use xlinkHref={props.imageid} /></svg>
                                    )}
                                    {props?.onsyncchange &&
                                        <p className="my-4">
                                            {props?.button && <u><span className="pointer-class " onClick={() => props?.onsyncchange()}>{props?.button}</span></u>}
                                        </p>
                                    }
                                </div>
                                : props.type == "create" ?
                                    <div className="text-center my-5">
                                        <p className="fw-bold">{props.tittle}</p>
                                        <svg className="nav_icon" width="150" height="151" role="img"><use xlinkHref="#svg_successpage" /></svg>
                                        {props?.description && <p>{props?.description}</p>}

                                        <p className="my-4">
                                            {
                                                props.secondarybtn != undefined ? <Link className="btn btn-outline-primary rounded-pill me-2" to={props.secondarylink}>{props.secondarybtn}</Link> : <></>
                                            }
                                            {
                                                props.primarybtn != undefined ? <Link className="btn btn-primary rounded-pill" to={props.primarylink} >{props.primarybtn}</Link> : <></>
                                            }
                                        </p>
                                        <p className="my-4">
                                            <Link to={props.returnlink}>{props.linktext}</Link></p>
                                    </div> : props.type == "createuser" ?
                                        <div className="text-center my-5">
                                            <p className="fw-bold">{props.tittle}</p>
                                            <svg className="nav_icon" width="150" height="151" role="img"><use xlinkHref="#svg_successpage" /></svg>
                                            <p>{props.description}</p>
                                            <p className="my-4">
                                                {
                                                    props.secondarybtn != undefined ? <a className="btn btn-outline-primary rounded-pill me-2" href={props.secondarylink}>{props.secondarybtn}</a> : <></>
                                                }
                                                {
                                                    props.primarybtn != undefined ? <a className="btn btn-primary rounded-pill" href={props.primarylink} >{props.primarybtn}</a> : <></>
                                                }
                                            </p>
                                            <p className="my-4">
                                                <Link to={props.returnlink}>{props.linktext}</Link></p>
                                        </div>
                                        : <></>
                        }

                    </> : <></>
            }
        </>
    );
}
export default ActionStatus