import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function LazyImageComponent({src, width, height, alt, className }) {
    return (
        <>
            <LazyLoadImage
                className={className}
                alt={alt}
                height={height}
                src={src} 
                width={width}
                effect="blur"
            />
        </>
    )
}