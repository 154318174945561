import React from "react";
import { useNavigate } from 'react-router-dom';
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";



const MemberPlanWidget = ({ item, memberPlan, booknow }) => {

    const { getLanguageContent } = useLanguageRes();
    const navigate = useNavigate();
    const { account } = useAccountSettings();

    const getTitle = (name) => {
        if (name == "Mental Health") return getLanguageContent("phsebev")
        else if (name == "Care Navigation") return getLanguageContent("phsecar")
        else if (name == "Functional Medicine") return getLanguageContent("phsemes")
        else if (name == "Urgent Care") return getLanguageContent("urgcre")
        else if (name == "Primary Care") return getLanguageContent("pricre")
        else if (name == "Muscle Joint") return getLanguageContent("musjoi")
    }


    const getDesc = (name) => {
        if (name == "Mental Health") return getLanguageContent("phsebeh")
        else if (name == "Care Navigation") return getLanguageContent("phsecco")
        else if (name == "Functional Medicine") return getLanguageContent("phsemed")
        else if (name == "Urgent Care") return getLanguageContent("uopcmin")
        else if (name == "Primary Care") return getLanguageContent("uopcchc")
        else if (name == "Muscle Joint") return getLanguageContent("imedcre")
    }

    const navigateDetailPage = (name, code) => {
        if (name == "Urgent Care") {
            return booknow("GT6")
        } else if (name == "Primary Care") {
            return booknow("SD3")
        }
        const _content = name == "Mental Health" ? "phseblm" : name == "Care Navigation" ? "phseclm" : name == "Functional Medicine" ? "phsemlm" : name == "Muscle Joint" ? "imdcrmu" : ""
        const _title = name == "Mental Health" ? "phsebev" : name == "Care Navigation" ? "phsecar" : name == "Functional Medicine" ? "phsemes" : name == "Muscle Joint" ? "musjoi" : ""

        return navigate(`/plan/detail?p=${code}&t=${_title}&c=${_content}`)
    }


    return (
        <>
            <div className="card border-0 w-100">
                <div className="card-body">
                    <div>
                        <h4>{getTitle(item?.name)}</h4>
                        <div dangerouslySetInnerHTML={{ __html: getDesc(item?.name)?.replace(/{account_code}/g, account?.code?.toLowerCase()) }}></div>
                    </div>
                </div>
                <div className="card-footer bg-white border-0 pt-0 pb-3">
                    <button type="button" onClick={() => navigateDetailPage(item?.name, memberPlan)} className="btn btn-primary btn-lg">{getLanguageContent("phsemor")}</button>
                </div>
            </div>
        </>
    )
}

export default MemberPlanWidget
