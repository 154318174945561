import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import he from "he";
import { useAccountSettings } from '../../services/Context/AccSettingContext';


const Footer = () => {

    const { accountSettings, account, getAccountsettingValue } = useAccountSettings();

    const [footer, setFooter] = useState(null);
    const [brandingText, setBrandingText] = useState(null);

    useEffect(() => {

        if (Array.isArray(accountSettings)) {
            const _branding = getAccountsettingValue(accountSettings, "CBT");
            if (_branding) {
                setBrandingText(_branding)
            }
        }
    }, [accountSettings]);


    //console.log(brandingText);

    useEffect(() => {

        if (Array.isArray(account?.portalSettings)) {

            const data = account?.portalSettings?.find((list) => list?.code == "FTC");
            if (data) {
                const cleanHtml = DOMPurify.sanitize(data?.value);
                const decodedHTML = he.decode(cleanHtml);
                //console.log(cleanHtml);
                setFooter(decodedHTML)
            }


        }

    }, [account]);


    //console.log(brandingText)
    return (
        <>
            <footer className="footer-container">
                <div>
                    {footer && (
                        <div dangerouslySetInnerHTML={{ __html: footer }}>
                        </div>
                    )}

                    {brandingText && (
                        <div className="text-center" dangerouslySetInnerHTML={{ __html: brandingText }}></div>
                    )}
                </div>
            </footer>
        </>
    );
}

export default Footer;