import React, { useContext, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { formValidation, getAge, getMemberId, getMsg } from "../../../../services/utils/validation";
import ProgressContext from "../../../../services/utils/progress";
import { serverGet, serverPost, serverPut } from "../../../../middleware/http";
import { apiConst, appConst } from "../../../../services/constant/constant";
import { useState } from "react";
import { useAccountSettings } from "../../../../services/Context/AccSettingContext";
import NotifyContext from "../../../../services/utils/notify";
import { useLanguageRes } from "../../../../services/Context/LanguageContext";
import { useProfile } from "../../../../services/utils/profileContext";


export default function FamilyMemberAdd({ selectedFamilyMember, toggle, isCreate }) {
    const [cateogry, setCateogry] = useState([]);
    const [notificationLog, setNotificationLog] = useState(null);
    const [dob, setDob] = useState();
    const [ageValidation, setAgeValidation] = useState(false);
    //const [statusFamilyMember, setStatusFamilyMember] = useState(true);

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm();
    const { progParams } = useContext(ProgressContext);
    const { accountDate } = useAccountSettings();
    const { notifyToast } = useContext(NotifyContext);
    const { getLanguageContent } = useLanguageRes();
    const { profileContext, getFamilyList } = useProfile();


    var today = new Date();
    var tomorrow = new Date(today);
    var tw = tomorrow.setDate(today.getDate() + 1);

    const getCateogry = () => {
        progParams(true);
        serverGet(`${apiConst.getcateogry}${appConst.cateogries.relationship}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setCateogry(res.data);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }


    const handleBack = (e) => {
        e.preventDefault();
        toggle();
    }


    //console.log(profileContext);
    const onSubmit = async (data) => {
        try {
            //console.log(data)
            progParams(true);
            const _memberId = await getMemberId();
            let formData = {
                memberId: _memberId,
                FirstName: data?.firstname,
                LastName: data?.lastname,
                DOB: Moment(data?.dob).format('YYYY-MM-DD'),
                GenderId: data?.gender,
                //Phone: data.phonenumber,
                Email: ageValidation ? data.email : selectedFamilyMember?.userId ? selectedFamilyMember?.email : profileContext?.resProfile.email,
                //StatusId: data.statusId ? 1 : 2,
                StatusId: selectedFamilyMember ? data.statusId ? 1 : 2 : 1,
                RelationshipId: data.relationship,
            }
            //formadata.expiryDate = Moment(data?.expiryDate).format('YYYY-MM-DD');


            if (selectedFamilyMember) {
                formData.memberId = selectedFamilyMember?.id;
                formData.PatientId = selectedFamilyMember?.patientId;
                formData.parentId = selectedFamilyMember?.parentId;
                //formData.phone = "";

                familyMemberUpdate(formData, selectedFamilyMember?.id)
            } else {
                familyMemberCreate(formData)
            }
            //const res = await serverPost(apiConst.familymembersave, formData)
            //progParams(false);

            //if (res?.status == 1) {
            //    notifyToast(res.messageCode[0]);
            //    toggle();
            //} else {
            //    var error = JSON.parse(res?.data?.errorMessage);
            //    notifyToast(error?.messageCode[0]);
            //}
        } catch (e) {
            console.log(e);
        }
    }


    const familyMemberCreate = (data) => {


        progParams(true);
        serverPost(apiConst.familymembersave, data).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    toggle();
                    getFamilyList();
                    notifyToast(res.messageCode[0]);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }


    const familyMemberUpdate = (data, id) => {


        progParams(true);
        serverPut(`${apiConst.familyMemberUpdate}${id}`, data).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    toggle();
                    getFamilyList();
                    notifyToast(res.messageCode[0]);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })

    }


    const getNotifactionLog = (patientid) => {

        progParams(true);
        serverGet(`${apiConst.getNotificationLog}${patientid}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setNotificationLog(res.data);
                }
            } catch (e) {
                console.log(e);
            }
        })

    }

    const patientInvite = () => {

        const _emailId = watch("email");

        progParams(true);
        serverGet(`${apiConst.patientInvite}${selectedFamilyMember?.patientId}?emailId=${encodeURIComponent(_emailId)}`).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    getNotifactionLog(selectedFamilyMember?.patientId);
                    notifyToast(res?.messageCode[0]);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })

    }


    //console.log(selectedFamilyMember)


    useEffect(() => {

        document.title = selectedFamilyMember ? "Edit family member" : "Add family member";
        getCateogry();

        if (selectedFamilyMember) {
            getNotifactionLog(selectedFamilyMember?.patientId)
        }
    }, [])



    useEffect(() => {

        if (selectedFamilyMember) {
            setDob(new Date(Moment(selectedFamilyMember?.dob)));
            setValue("relationship", selectedFamilyMember?.relationshipId)
            setValue("firstname", selectedFamilyMember?.firstName)
            setValue("lastname", selectedFamilyMember?.lastName)
            setValue("dob", new Date(Moment(selectedFamilyMember?.dob)))
            setValue("gender", selectedFamilyMember?.genderId)
            setValue("email", selectedFamilyMember?.email)
            setValue("statusId", selectedFamilyMember?.statusId == "1" ? true : false)

        }
        //                        setValue('expiryDate', new Date(response?.expiryDate));

    }, [selectedFamilyMember])

    //const handleDobChange = (value) => {
    //    setDob(value);

    //    //const _getAge = getAge(value);

    //    ////console.log(_getAge)
    //    //if (_getAge >= 18 && !selectedFamilyMember?.userId) {
    //    //    setValue("email", '');

    //    //    //reset({ email: '' });

    //    //}

    //}

    useEffect(() => {

        if (dob) {
            const _getAge = getAge(dob);

            if (_getAge >= 18) {
                setAgeValidation(true);
            } else {
                setAgeValidation(false);
            }
        }

    }, [dob])

    useEffect(() => {
        if (selectedFamilyMember)
            setValue("relationship", selectedFamilyMember?.relationshipId)
    }, [cateogry])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    {/*<div className="col-xl-7 text-end">*/}
                    {/*    <button className="btn btn-lg btn-primary" type="submit">Resend Invite</button>*/}
                    {/*</div>*/}
                    <div className="col-xl-7">
                        <div className="mb-3">
                            <label htmlFor="genderId" className="form-label">{getLanguageContent("fmbrel")}<span className="text-danger star">*</span></label>
                            <select className="form-control form-select" id="relationship"  {...register("relationship", { required: getMsg(301) })}>
                                <option value="">Select</option>
                                {cateogry?.length > 0 && cateogry?.map((list, i) => (
                                    <option key={i} value={list?.id}>{list?.name}</option>
                                ))}
                            </select>
                            {errors.relationship?.message && <span className="text-danger">{errors.relationship?.message}</span>}
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="mb-3">
                            <label htmlFor="firstname" className="form-label">{getLanguageContent("fmbfst")}<span className="text-danger star">*</span></label>
                            <input type="text" className="form-control" id="firstname" placeholder={getLanguageContent("fmbent")}  {...register("firstname", { required: getMsg(301), maxLength: { value: 50, message: getMsg("305") } })} />
                            {errors?.firstname?.message && <p className="text-danger">{errors?.firstname?.message}</p>}
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="mb-3">
                            <label htmlFor="lastname" className="form-label">{getLanguageContent("fmblst")}<span className="text-danger star">*</span></label>
                            <input type="text" className="form-control" id="lastname" placeholder={getLanguageContent("fmbelt")} {...register("lastname", { required: getMsg(301), maxLength: { value: 50, message: getMsg("305") } })} />
                            {errors?.lastname?.message && <p className="text-danger">{errors?.lastname?.message}</p>}
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="dob">{getLanguageContent("fmbdob")}<span className="text-danger star">*</span></label>
                            <Controller
                                control={control}
                                name="dob"
                                rules={{
                                    required: getMsg(301)
                                }}
                                render={({ field }) => (
                                    <DatePicker
                                        id='dob'
                                        name="dob"
                                        selected={field?.value}
                                        dateFormat={accountDate?.value ? accountDate?.value?.toLowerCase()?.replace(/mm/, 'MM') : 'MM/dd/yyyy'}
                                        className="form-control"
                                        onChange={(date) => {
                                            field.onChange(date)
                                            setDob(date);
                                        }}
                                        placeholderText={getLanguageContent("fmbnom")}
                                        showMonthDropdown
                                        showYearDropdown
                                        wrapperClassName="w-100"
                                        dropdownMode="select"
                                        //minDate={tw}
                                        maxDate={new Date()}
                                    />
                                )}
                            />
                            {(errors?.dob?.message && !dob) && <p className="text-danger">{errors?.dob?.message}</p>}
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="mb-3">
                            <label className="form-label d-block">{getLanguageContent("fmbger")}<span className="text-danger star">*</span></label>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value="1" id="rdoMale" {...register("gender", { required: getMsg(301) })} />
                                <label className="form-check-label" htmlFor="rdoMale">Male</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value="2" id="rdoFemale" {...register("gender", { required: getMsg(301) })} />
                                <label className="form-check-label" htmlFor="rdoFemale">Female</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value="3" id="rdoOther" {...register("gender", { required: getMsg(301) })} />
                                <label className="form-check-label" htmlFor="rdoOther">Other</label>
                            </div>
                            {errors?.gender?.message && <p className="text-danger">{errors?.gender?.message}</p>}

                        </div>
                    </div>

                    {/*<div className="col-xl-7">*/}
                    {/*    <div className="mb-3">*/}
                    {/*        <label htmlFor="phonenumber" className="form-label">{getLanguageContent("fmbphn")}</label>*/}
                    {/*        <input type="text" id="phonenumber" className="form-control" placeholder={getLanguageContent("fmbnum")}  {...register("phonenumber", { pattern: formValidation.number.pattern, maxLength: { value: 20, message: getMsg("305") } })} />*/}
                    {/*        {errors.phonenumber?.message && <span className="text-danger">{errors.phonenumber?.message}</span>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {(ageValidation) && (
                        <div className="col-xl-7">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">{getLanguageContent("fmbeml")}<span className="text-danger star">*</span></label>
                                <input disabled={selectedFamilyMember?.userId ? true : false} type="text" className="form-control body-secondary-color" id="email" placeholder={getLanguageContent("fmbads")} {...register("email", { required: getMsg(301), pattern: formValidation.email.pattern, maxLength: { value: 100, message: getMsg("305") } })} />
                                {errors.email?.message && <span className="text-danger">{errors.email?.message}</span>}
                                {(selectedFamilyMember && !notificationLog?.userId && selectedFamilyMember?.platformAccessId == "2") && (
                                    <div className="text-end my-2 text-primary pointer-class" onClick={patientInvite}><u>{notificationLog?.email ? "Resend invite" : "Send invite"}</u></div>
                                )}
                            </div>
                        </div>
                    )}

                    {(selectedFamilyMember) && (
                        <div className="col-xl-7">
                            <div className="mb-3 ">
                                <div className="form-control form-check form-switch">
                                    <label className="form-check-label" for="status">{getLanguageContent("fmbsts")}</label>
                                    <input className="form-check-input float-end" type="checkbox" role="switch" id="swmedicineStatus" {...register("statusId")} />
                                    <span className={`badge rounded-pill my-1 float-end me-5 ${(watch('statusId') ? "text-bg-success" : "text-bg-danger")}`}>{watch('statusId') ? getLanguageContent("fmbact") : getLanguageContent("fmbive")}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {(ageValidation && !selectedFamilyMember) && (
                        <div className="mb-3">
                            This family member is an adult and an invite will be send to join the client portal
                        </div>
                    )}


                    <div className="col-xl-7">
                        <div className="mb-3 text-end">
                            <button className="btn btn-lg btn-secondary me-2" onClick={(e) => handleBack(e)}>{getLanguageContent("fmbbck")}</button>
                            {isCreate && (
                                <button className="btn btn-lg btn-primary" type="submit">{getLanguageContent("fmbsbm")}</button>
                            )}
                        </div>
                    </div>


                </div>
            </form >
        </>
    )
}