import React, { useContext, useEffect, useState } from "react"
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { formValidation, getMsg, getSearchParams } from '../../../services/utils/validation';
import { PasswordField } from "../../../services/utils/forms";
import { serverPost } from "../../../middleware/http";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { apiConst } from "../../../services/constant/constant";
import { useProfile } from "../../../services/utils/profileContext";
import { useLanguageRes } from '../../../services/Context/LanguageContext';


const ResetPassword = () => {
    const { register, handleSubmit, formState: { errors }, getValues, } = useForm();
    const [urlParams] = useSearchParams();
    const { notifyToast } = useContext(NotifyContext)
    const { progParams } = useContext(ProgressContext);
    const [resetPassword, setResetPassword] = useState(false);
    const {getLanguageContent} = useLanguageRes();


    //const { profileContext } = useProfile();
    //const navigate = useNavigate();

    const onSubmit = data => {
        //console.log(data);
        var reqdata = {
            token: getSearchParams("token"),
            userId: urlParams.get("id"),
            newPassword: data.password
        }
        progParams(true);
        serverPost(apiConst.postresetpassword, reqdata).then((res) => {
            progParams(false);
            try {
                if (res.status == 1) {
                    setResetPassword(true);
                    notifyToast(res?.messageCode[0]);
                } else {
                    var error = JSON.parse(res.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })
    };


    //useEffect(() => {
    //    if (profileContext !== undefined && profileContext) {
    //        navigate(`/dashboard`);
    //    }
    //}, [profileContext])

    useEffect(() => {
        document.title = 'Set new password';
    }, []);

    return (
        <>
            <h6 className="card-title text-uppercase fw-bold mb-3">{getLanguageContent("setpsswrd")}</h6>
            {!resetPassword &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <PasswordField
                            name="password"
                            label={getLanguageContent("newpsswrd")}
                            //label={langResource.getValue("newpwd")}
                            errors={errors}
                            register={register}
                            placeholder={getLanguageContent("entnewpsswrd")}
                            icon={true}
                            validationSchema={{
                                required: getMsg(301),
                                maxLength: { value: 50, message: getMsg("305") },
                                pattern: formValidation.password.pattern,
                            }}
                        />
                        <p>
                            <small>{getLanguageContent("strngpsswrd")}</small>
                        </p>
                    </div>
                    <div className="mb-3">
                        <PasswordField
                            name="NewPassword"
                            label={getLanguageContent("cnfmpsswrd")}
                            placeholder={getLanguageContent("entcnfmpsswrd")}
                            errors={errors}
                            register={register}
                            icon={true}
                            validationSchema={{
                                required: getMsg(301),
                                validate: value => value === getValues('password') || getMsg(316),
                                maxLength: {
                                    value: 50, message: getMsg("305")
                                }
                            }}
                        />
                    </div>
                    <div className="mb-2">
                        <button className="btn btn-primary">{getLanguageContent("savepsswrd")}</button>
                    </div>
                </form>
            }
            {resetPassword &&
                <>
                <div className="text-center my-5">                   
                        <svg className="nav_icon" width="150" height="150" role="img" aria-label="Dashboard"><use xlinkHref="#svg_successpage" /></svg>
                    <p className="fw-bold">{getLanguageContent("chngsuc")}</p>
                    <p><small></small>{getLanguageContent("sgnnew")}</p>
                    </div>
                    <div className="mb-5">
                    <Link to="/security/login" className="btn btn-primary ">{getLanguageContent("signin")}</Link>
                    </div>
                </>
            }
        </>

    );
}

export default ResetPassword;