import React, { useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { serverGet } from "../../../middleware/http";
import { apiConst, appConst } from "../../../services/constant/constant";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { getAuthProfile, getSearchParams, isEnabled, updateSearchParams } from "../../../services/utils/validation";
import MemberSpecialty from "./MemberSpeciality";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useProfile } from "../../../services/utils/profileContext";

export default function Specialty() {

    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [defaultSpecialty, setDefaultSpecialty] = useState(null);
    const [list, setList] = useState(null);
    const { notifyToast } = useContext(NotifyContext);
    const navigate = useNavigate();
    const { getLanguageContent } = useLanguageRes();
    const { progParams } = useContext(ProgressContext);
    const { checkUserProfile, getFamilyList } = useProfile();
    const { accountSettings } = useAccountSettings();

    const onProceed = () => {
        if (selectedSpecialty && selectedSpecialty?.trim()) {
            updateSearchParams("spid", selectedSpecialty);
            const _brtype = getSearchParams("brtype");
            const withoutProvider = _brtype == "RW8" || _brtype == "GT6"

            if (withoutProvider) {
                return navigateSharedCalender(selectedSpecialty, getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : "", _brtype)
            }
            getProvider(selectedSpecialty, _brtype);

        } else {
            return notifyToast(348); //Select your preferred specialty to proceed
        }
    }

    const onSkip = () => {
        if (list?.length > 0 && defaultSpecialty) {
            const _brtype = getSearchParams("brtype");
            const withoutProvider = _brtype == "RW8" || _brtype == "GT6"

            updateSearchParams("spid", defaultSpecialty);
            if (withoutProvider) {
                return navigateSharedCalender(defaultSpecialty, getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : "", _brtype)
            }
            getProvider(defaultSpecialty, _brtype);
        }
    }


    const getProvider = async (id, type) => {
        try {
            progParams(true);
            const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${id}&kwd=-${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}`);
            if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${id}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&minP=${ProviderRes?.data[0]?.minimumPrice}&sinp=t&brtype=${type}`);
            } else {
                return navigate(`/provider?spid=${id}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&brtype=${type}`);
            }
        } catch (e) {
            console.log(e);
        } finally {
            progParams(false);
        }
    }



    const navigateSharedCalender = async (spid, residingId, type) => {
        const addProfileUrl = `/addprofile?spid=${spid}${residingId ? `&${residingId}` : ""}&brtype=${type}`;
        const _identificationUrl = `/idverification/upload?spid=${spid}${residingId ? `&${residingId}` : ""}&brtype=${type}`;
        const bookingUrl = `/booking?spid=${spid}${residingId ? `&${residingId}` : ""}&brtype=${type}`;
        const _familyMemberUrl = `/selectfamilymember?spid=${spid}${residingId ? `&${residingId}` : ""}&brtype=${type}`
        const redirectUrl = {
            path: bookingUrl,
            bookingUrl: bookingUrl,
            familyMemberUrl: _familyMemberUrl,
            profileUrl: addProfileUrl,
        }
        if (!getAuthProfile.getProfileAuth()) {
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));

            return navigate(`/security/login?c=y${residingId ? `&${residingId}` : ""}`)
        }

        const _isProfile = await checkUserProfile();
        const _isFamilyMember = await getFamilyList();






        if (!_isProfile?.pofileCompleted) {
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            navigate(addProfileUrl);
        } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
            sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            navigate(_identificationUrl);
        } else if (_isFamilyMember) {
            navigate(_familyMemberUrl);
        } else {
            navigate(bookingUrl);
        }
    }

    //useLayoutEffect(() => {
    //    //let _orderDetails = localStorage.getItem("orderDetail")

    //    //if (_orderDetails) {
    //    //    _orderDetails = JSON.parse(_orderDetails);
    //    //    let _stateId = _orderDetails?.profile?.State
    //    //    if (_stateId) {
    //    //        updateSearchParams("rsid", _stateId)
    //    //    }
    //    //}
    //}, [])

    return (
        <div className="px-2 px-md-0">
            <MemberSpecialty setSelectedSpecialty={setSelectedSpecialty} selectedSpecialty={selectedSpecialty} setList={setList} setDefaultSpecialty={setDefaultSpecialty} defaultSpecialty={defaultSpecialty} />
            {(list && list?.length > 0) && (
                <div className="row">
                    <div className="col-12 my-5 text-end">
                        {defaultSpecialty && (
                            <button className="btn btn-lg btn-secondary me-2" onClick={onSkip}>{getLanguageContent("skip")}</button>
                        )}
                        <button className="btn btn-lg btn-primary" onClick={onProceed}>{getLanguageContent("prced")}</button>
                    </div>
                </div>
            )}
        </div>
    )
}