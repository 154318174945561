import React from "react";
import Insurance from "../insurance/Insurance";


export default function EncounterInsurance() {
    return (
        <>
            <Insurance insuranceFlow={true} />
        </>
    )
}