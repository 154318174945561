import React from "react";
import Forms from "../forms";
import { appConst } from "../../../../../services/constant/constant";
import { useLanguageRes } from "../../../../../services/Context/LanguageContext";

export default function PatientForms() {

    const { getLanguageContent } = useLanguageRes();


    return (
        <>
            <div>
                <Forms level="patient-forms" areaId={appConst.areaId.patientLevelForms} />
            </div>
        </>
    )
}