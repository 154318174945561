import React, { useEffect, useRef, useContext, useState, useCallback } from 'react';
import * as bootstrap from 'bootstrap';
import Webcam from "react-webcam";
import Moment from 'moment';
import { serverDelete, serverPost } from '../../middleware/http';
import { apiConst } from '../../services/constant/constant';
import NotifyContext from '../../services/utils/notify';
import ProgressContext from '../../services/utils/progress';
import { ecnSession } from '../../services/utils/validation';
import { useLanguageRes } from '../../services/Context/LanguageContext';
import { useAccountSettings } from '../../services/Context/AccSettingContext';
import LazyImageComponent from '../../services/utils/LazyImageComponent';

//recordtypeid { size, setDocState, encounter, fileTypes, disText, maxFile }
const pattern = /^[^\/:*?"<>|#]*$/;
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const DocumentUpload = (props) => {
    //const { addSelectedDocs, selectedDocs, removeSelectedDocs } = useDocUpload();
    const [selectedFiles, setSelectedFiles] = useState([]); // rejectedfiles due to validation
    const [uploadedFiles, setUploadedFiles] = useState([]); // localstorage & successfully uploaded files
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

    //const [switchCamera, setSwitchCamera] = useState(false);
    const fileInputRef = useRef(null);
    const modalBackdropRef = useRef(null);
    const webcamRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isMediaPermission, setIsMediaPermission] = useState(false);
    const sizeInBytes = props?.size * 1024 * 1024
    const { notifyToast } = useContext(NotifyContext);
    const { progParams } = useContext(ProgressContext);
    const { conditionDocLength } = useAccountSettings();

    //console.log(isMediaPermission);
    const urlSearchParams = new URLSearchParams(window.location.search);

    const { getLanguageContent } = useLanguageRes();

    //HANDLE DRAG & DROP WHILE SELECT FILES

    const handleDrop = async (e) => {
        try {
            e.preventDefault();
            const docs = Array.from(e.dataTransfer.files);
            if (props?.cameraUpload && (!checkFileLength() || (docs?.length > parseInt(conditionDocLength) - props?.conditionDocs?.length))) {
                // return notifyToast(336);

                return notifyToast(353, undefined, undefined, {
                    file_size: props?.maxFile
                });
            }

            progParams(true);
            const sessionStore = ecnSession();
            const member_id = localStorage.getItem("memberId");
            const selected_Files = [...selectedFiles];

            let uploadedFiles = [];
            let condition_docs = [];
            if (props?.maxFile && docs?.length > props?.maxFile) {
                //alert('Please select up to 5 files');
                // notifyToast(336);
                e.target.value = null;

                return notifyToast(353, undefined, undefined, {
                    file_size: props?.maxFile
                });
            } else {
                if (sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
                    uploadedFiles = sessionStore?.uploadid;
                }

                for (const doc of docs) {

                    const base64Data = await toBase64(doc);
                    if (doc.size < sizeInBytes && props?.fileTypes?.includes(doc.type) && pattern.test(doc?.name)) {
                        const current_file = {
                            memberId: member_id,
                            fileName: doc?.name,
                            fileContent: `data:${doc.type};base64,` + base64Data,
                            compName: props?.name
                        }

                        const res = await serverPost(apiConst.docupload, current_file);
                        if (res.status == 1) {
                            const temp = {
                                name: doc?.name,
                                src: res.data,
                                encId: props?.encounter ? urlSearchParams.get('eid') : null,
                                type: window.location.pathname,
                                size: (doc.size / 1024).toFixed(2),
                                compName: props?.name,
                                preview: props?.cameraUpload

                            }
                            uploadedFiles.push(temp);
                            if (props?.cameraUpload) {
                                condition_docs.push(temp);
                            }
                            // File uploaded successfully
                        }
                    } else {
                        selected_Files.push({
                            src: doc,
                            name: props?.name,
                            preview: props?.cameraUpload
                        });
                    }
                }
            }

            if (uploadedFiles.length > 0) {
                ecnSession(uploadedFiles, "uploadid");
                setUploadedFiles(uploadedFiles);
            }


            if (condition_docs.length > 0) {
                if (Array.isArray(props?.conditionDocs)) {
                    const temp = [...props?.conditionDocs];
                    if (temp.length > 0) {
                        temp?.map((list) => {
                            condition_docs.push(list);
                        })
                    }
                }

                if (props?.cameraUpload) {
                    props?.setConditionDocs(condition_docs);
                }            }
            setSelectedFiles(selected_Files);
            props?.setDocState(selected_Files);
        } catch (error) {
            console.error(`Error processing file:`, error);

        } finally {
            progParams(false);
        }
    };

    const  getBase64Size= (base64String) =>  {
        // Remove the padding characters, if any
        let padding = 0;
        if (base64String.endsWith('==')) padding = 2;
        else if (base64String.endsWith('=')) padding = 1;

        // Calculate the byte size
        const byteSize = (base64String.length * 3 / 4) - padding;

        return byteSize;
    }

    //console.log(capturedImage)

    //HANDLE CLICK WHILE SELECT FILES

    const handleFileSelect = async (e) => {
        try {
            e.preventDefault();
            const docs = Array.from(e.target.files);
            if (props?.cameraUpload && (!checkFileLength() || (docs?.length > parseInt(conditionDocLength) - props?.conditionDocs?.length))) {

                return notifyToast(353, undefined, undefined, {
                    file_size: props?.maxFile
                });
                //  return notifyToast(336);


            }

            progParams(true);
            const sessionStore = ecnSession();
            const selected_Files = [...selectedFiles];
            let condition_docs = [];

            const member_id = localStorage.getItem("memberId");
            let uploadedFiles = [];

            if (props?.maxFile && docs?.length > props?.maxFile) {


                //notifyToast(336);
                e.target.value = null;
                return notifyToast(353, undefined, undefined, {
                    file_size: props?.maxFile
                });
            } else {
                if (sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
                    uploadedFiles = sessionStore?.uploadid;
                }
                for (const doc of docs) {

                    const base64Data = await toBase64(doc);

                    if (doc.size < sizeInBytes && props?.fileTypes?.includes(doc.type) && pattern.test(doc?.name)) {
                        const current_file = {
                            memberId: member_id,
                            fileName: doc?.name,
                            fileContent: `data:${doc.type};base64,` + base64Data
                        }


                        const res = await serverPost(apiConst.docupload, current_file);
                        if (res.status === 1) {
                            const temp = {
                                name: doc?.name,
                                src: res.data,
                                encId: props?.encounter ? urlSearchParams.get('eid') : null,
                                type: window.location.pathname,
                                size: (doc.size / 1024).toFixed(2),
                                compName: props?.name,
                                preview: props?.cameraUpload

                            }
                            uploadedFiles.push(temp);
                            if (props?.cameraUpload) {
                                condition_docs.push(temp);
                            }
                        }
                    } else {
                        selected_Files.push({
                            src: doc,
                            compName: props?.name,
                            preview: props?.cameraUpload
                        });
                    }

                }
            }
            if (uploadedFiles.length > 0) {
                ecnSession(uploadedFiles, "uploadid");

                setUploadedFiles(uploadedFiles)  //successfully uploaded files
            }

            if (condition_docs.length > 0) {
                if (Array.isArray(props?.conditionDocs)) {
                    const temp = [...props?.conditionDocs];
                    if (temp.length > 0) {
                        temp?.map((list) => {
                            condition_docs.push(list);
                        })
                    }
                }
                if (props?.cameraUpload) {
                    props?.setConditionDocs(condition_docs);
                }
            }

            setSelectedFiles(selected_Files);  //validation failed  files
            props?.setDocState(selected_Files);  //validation failed   files
        } catch (error) {
            console.error(`Error processing file:`, error);

        } finally {
            progParams(false);
        }
    };

    const handleCameraSave = async (e) => {
        try {
            closeModal();
            progParams(true);
            e.preventDefault();
            if (props?.cameraUpload && !checkFileLength()) {
                return notifyToast(353, undefined, undefined, {
                    file_size: props?.maxFile
                });
                //  return notifyToast(336);

            }

            const sessionStore = ecnSession();
            const member_id = localStorage.getItem("memberId");
            const timestamp = Moment().format('YYYYMMDDHHmmss');
            const imageType = capturedImage.split(';')[0].split('/')[1];
            let uploadedFiles = [];
            const condition_docs = Array.isArray(props?.conditionDocs) ? [...props?.conditionDocs] : [];
            if (sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
                uploadedFiles = sessionStore?.uploadid;
            }

            const current_file = {
                memberId: member_id,
                fileName: `capture_${timestamp}.${imageType}`,
                fileContent: capturedImage
            }

           // console.log(getBase64Size(capturedImage));

            const res = await serverPost(apiConst.docupload, current_file);
            if (res.status == 1) {
                const temp = {
                    name: `capture_${timestamp}`,
                    src: res.data,
                    encId: props?.encounter ? urlSearchParams.get('eid') : null,
                    type: window.location.pathname,
                    compName: props?.name,
                    preview: props?.cameraUpload
                }
                uploadedFiles.push(temp);
                condition_docs.push(temp);
            }

            if (uploadedFiles.length > 0) {
                ecnSession(uploadedFiles, "uploadid");
                setUploadedFiles(uploadedFiles)  //successfully uploaded files

                if (props?.cameraUpload) {
                    props?.setConditionDocs(condition_docs);
                }
            }
        } catch (error) {
            console.error(`Error processing file:`, error);

        } finally {
            progParams(false);
        }
    }

    const checkFileLength = () => {
        return Array.isArray(props?.conditionDocs) && props?.conditionDocs?.length < parseInt(conditionDocLength);
    }

    //console.log(props?.conditionDocs);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
    });


    const handleClick = (e) => {
        if (props?.cameraUpload && !checkFileLength()) {
            return notifyToast(353, undefined, undefined, {
                file_size: props?.maxFile
            });
        }
        // return notifyToast(336);
        e.target.value = '';
        fileInputRef.current.click();

    };


    const removeSelectedFiles = (doc, filepath) => {
        const uploaded_Files = [...uploadedFiles];
        const member_id = localStorage.getItem("memberId");
        if (doc == 'delete') {
            if (window.confirm('Are you sure you want to delete?')) {
                progParams(true);
                serverDelete(`${apiConst.docdelete}/${member_id}?fp=${filepath}`, "").then((res) => {
                    progParams(false);
                    try {
                        if (res.status == 1) {
                            notifyToast(res.messageCode[0]);
                            const docIndex = uploaded_Files.findIndex((item) => item?.src == filepath && item.compName == props?.name);
                            if (docIndex != -1) {
                                uploaded_Files.splice(docIndex, 1);
                                if (props?.cameraUpload) {
                                    const condition_docs = uploaded_Files?.filter((list) => list?.preview);
                                    const _docs = props?.conditionDocs?.filter((list) => list?.typeId == 301);

                                    props?.setConditionDocs([..._docs, ...condition_docs]);
                                }

                                setUploadedFiles(uploaded_Files);
                                ecnSession(uploaded_Files, "uploadid");
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }).catch((e) => {
                    console.log(e)
                })
            }
        } else {
            const selected_Files = [...selectedFiles];
            const updatedDocs = selected_Files.filter((d) => d.src !== doc && d.compName == props?.name);
            setSelectedFiles(updatedDocs);
            props?.setDocState(updatedDocs);
        }

    };
    const getCameraPermission = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    resolve(true);
                })
                .catch(function (error) {
                    notifyToast(216);
                    reject(false);
                });
        });
    }

    const openModal = async () => {
        try {
            const permission = await getCameraPermission();
            if (permission) {
                if (props?.cameraUpload && !checkFileLength()) {
                    //return notifyToast(336);

                    return notifyToast(353, undefined, undefined, {
                        file_size: props?.maxFile
                    });
                }
                setCapturedImage(null);
                const modal = new bootstrap.Modal(document.getElementById(`backdrop${props?.name}`));
                if (modal) {
                    modal.show();

                }
            } else {
                notifyToast(216);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleOpen = (e) => {
        e.stopPropagation();
        e.preventDefault();
        openModal();
    }


    const closeModal = () => {
        const bsModalBackdrop = modalBackdropRef.current;
        const bsModal = bootstrap.Modal.getInstance(bsModalBackdrop);
        if (bsModal) {
            setCapturedImage(null);
            bsModal.hide();
        }
    };


    const retakePhoto = (e) => {

        e.preventDefault();
        setTimeout(() => {
            setCapturedImage(null);
        }, 400)

    };


    const capturePhoto = useCallback((e) => {
        e.preventDefault();
        const imageSrc = webcamRef.current.getScreenshot();

        setCapturedImage(imageSrc);
    }, [webcamRef]);


    let videoConstraints = {
        facingMode: facingMode,
    };


    useEffect(() => {
        const bsModalBackdrop = modalBackdropRef.current;
        return () => {
            const bsModal = bootstrap.Modal.getInstance(bsModalBackdrop);
            if (bsModal) {
                bsModal.hide();
            }
        };
    }, []);


    useEffect(() => {
        const sessionStore = ecnSession();
        if (sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
            setUploadedFiles(sessionStore?.uploadid)
        }

    }, []);

    const handleUserMediaError = () => {
        setIsMediaPermission(false);
    }

    const handleUserMedia = () => {
        setIsMediaPermission(true);
    }

    return (
        <>
            {uploadedFiles?.length > 0 &&
                uploadedFiles.filter(item => (props?.encounter ?
                    (item.encId == urlSearchParams.get('eid') && item?.type == window.location.pathname && item?.compName == props?.name) :
                    (item.encId === null && item?.type == window.location.pathname && item?.compName == props?.name)))
                    .map((list, index) => (
                        <>
                            {(list?.src && props?.showPreview) ?
                                <>
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 ">
                                        <div className="border rounded p-1 h-100">
                                            <div className="position-relative d-flex justify-content-center align-items-center h-100 ">
                                                {list?.src && (
                                                    <LazyImageComponent
                                                        src={`doc/getimage/true?url=${encodeURIComponent(list?.src)}`}
                                                        alt={list?.name}
                                                        height="80"
                                                    />
                                                )}
                                                <button type="button" className="btn btn-sm btn-secondary border rounded-circle position-absolute top-0 end-0 border-secondary" style={{ padding: "5px", paddingTop: "3px" }} onClick={() => {
                                                    removeSelectedFiles("delete", list?.src, index)
                                                }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" /></svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <div className="col-xl-6 mb-3">
                                    <div key={index} className="d-flex border rounded align-items-center">
                                        {/*{props?.cameraUpload ? <img className="img-fluid" width="60" height="50" src={`doc/getimage/true?url=${encodeURIComponent(list?.src)}`} alt={list?.name} /> :*/}
                                        <>
                                            <div className="px-2">
                                                <span className="fs-1 text-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                </svg></span>
                                            </div>
                                            <div className="py-1 truncate-text flex-grow-1">
                                                {list?.name}<br />
                                            {/*    {list?.size && <small>{list?.size} kb</small>}*/}
                                            </div>
                                        </>
                                        {/*}*/}
                                        <div className="py-2 ms-auto mx-2">
                                            <button onClick={() => {
                                                removeSelectedFiles("delete", list?.src, index)
                                            }} type="button" className="btn btn-outline-dark border-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    ))}

            {selectedFiles?.length > 0 && selectedFiles?.filter((list) => list?.compName == props?.name)?.map((item, index) => (
                <>
                    {(props?.fileTypes?.includes(item?.src?.type) && props?.showPreview) ?
                        <>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <div className="border border-danger-subtle rounded rounded p-1 h-100">
                                    <div className="position-relative d-flex justify-content-center align-items-center h-100">
                                        <LazyImageComponent
                                            src={URL.createObjectURL(item?.src)}
                                            alt={item?.src?.name}
                                            height="80"
                                        />
                                        <button type="button" className="btn btn-sm btn-secondary border rounded-circle position-absolute top-0 end-0 border-secondary" style={{ padding: "5px", paddingTop: "3px" }} onClick={() => {
                                            removeSelectedFiles(item?.src)
                                        }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" /></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </> :

                        <div key={index} className="col-xl-6 mb-3">
                            <div key={index} className="d-flex align-items-center border border-danger-subtle rounded">
                                <>
                                    <div className="px-2">
                                        <span className="fs-1 text-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                        </svg></span>
                                    </div>
                                    <div className="py-1 truncate-text  flex-grow-1">
                                        {item?.src?.name} <br />
                                        {item?.src?.size && (
                                            <small>{(item?.src?.size / 1024).toFixed(2)} kb</small>
                                        ) }
                                        {item?.src?.size > sizeInBytes && (<> <br /><small className="text-danger">File size is greater than {props?.size} mb.</small> </>)}
                                        {!props?.fileTypes?.includes(item?.src?.type) && (<><br /><small className="d-block text-danger">{`File type is not supported.`}</small></>)}
                                        {!pattern.test(item?.src?.name) && (<><br /><small className="d-block text-danger">{`File name is not supported.`}</small></>)}
                                    </div>
                                </>

                                <div className="py-2 ms-auto mx-2">
                                    <button onClick={() => {
                                        removeSelectedFiles(item?.src)
                                    }} type="button" className="btn btn-outline-dark border-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </>
            ))}


            <div className="col-12">
                <div
                    className="d-flex border border-2 border-style-dash rounded  p-3 pointer-class"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    onClick={(e) => handleClick(e)}
                >
                    <input
                        className="d-none"
                        accept={props?.fileTypes?.join(',')}
                        type="file"
                        ref={fileInputRef}
                        multiple
                        onChange={handleFileSelect}
                    />
                    <div className="px-2 me-2 my-auto">
                        <span className="fs-1 text-secondary">+</span>
                    </div>
                    <div className="py-1">Drag and drop or browse files to upload documents
                        <br />
                        <small className="text-center">{getLanguageContent("suppfile")}: {props?.disText?.map((language, i, arr) => (
                            <small key={i}>{language}{i != (arr.length - 1) ? ', ' : ''}</small>
                        ))} {getLanguageContent("maxsize")} {props?.size} MB.</small>
                        {(props?.enableCamera) && (
                            <>
                                <br />
                                <span className="me-2 text-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image-fill me-2" viewBox="0 0 16 16">
                                    <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                                </svg> <u>Browse</u></span>
                                <span className="pointer-class text-primary" onClick={(e) => {
                                    handleOpen(e)
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera me-2" viewBox="0 0 16 16">
                                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
                                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                                    </svg>
                                    <u>Camera</u>
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {(props?.enableCamera) && (
                <div ref={modalBackdropRef} className="modal fade" id={`backdrop${props?.name}`} data-bs-keyboard="false" data-bs-backdrop="static" tabIndex="-1" aria-labelledby={`backdropLabel${props?.name}`} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id={`backdropLabel${props?.name}`}>Take photo</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => closeModal(e)}></button>
                            </div>
                            <div className="modal-body d-flex justify-content-center">
                                <div>
                                    {capturedImage ? (
                                        <img className="img-fluid" alt="user" src={capturedImage} />
                                    ) : (
                                        <Webcam
                                            width="100%"
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            mirrored={false}
                                            videoConstraints={videoConstraints}
                                            screenshotQuality={1}
                                            onUserMediaError={handleUserMediaError}
                                            onUserMedia={handleUserMedia}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                                {capturedImage ? (
                                    <div>
                                        <button className="btn btn-info me-2" onClick={(e) => retakePhoto(e)}>Retake</button>
                                        <button className="btn btn-primary me-2" onClick={(e) => handleCameraSave(e)}>Save</button>
                                    </div>
                                ) : (
                                    <div>
                                        <button onClick={(e) => capturePhoto(e)} type="button" className="btn btn-primary me-2">Take photo</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DocumentUpload;




