import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import he from "he";
import { serverGet } from '../../middleware/http';
import { apiConst } from '../../services/constant/constant';
import { useEncounter } from '../../services/Context/JoinCall';
import { useProfile } from '../../services/utils/profileContext';
import { ecnSession, getAuthProfile } from '../../services/utils/validation';
import CountdownTimer from "../../services/utils/CountdownTimer";
import { useLanguageRes } from '../../services/Context/LanguageContext';
import { useAccountSettings } from '../../services/Context/AccSettingContext';


const NavMenu = ({ home }) => {

    const { account } = useAccountSettings();

    const [navContent, setNavContent] = useState(null);
    const { getLanguageContent } = useLanguageRes();

    //const location = useLocation();
    //const { profileContext, imageUrl } = useProfile();
    //const { currentEnc } = useEncounter();
    //const { languageRes, getLanguageRes } = useLanguageRes();


    //function CheckPath(pathnameToCheck) {
    //    if (location.pathname === pathnameToCheck) {
    //        return "border-top border-5 border-primary ";
    //    }
    //    return 'mt-2';
    //}

    //const logout = () => {
    //    serverGet(apiConst.authlogout).then((res) => {
    //        try {

    //            if (res?.status == 1) {
    //                localStorage.removeItem('langResource');
    //                ecnSession("clear");
    //                getAuthProfile.clearProfileAuth();
    //                sessionStorage.removeItem('redirectUrl');
    //                setTimeout(() => {
    //                    window.location.href = "/security/login"
    //                }, 400)
    //            }
    //        } catch (e) {
    //            console.log(e)
    //        }
    //    })
    //}
    //let activemenu = window.location.pathname.split('/')[1];



    useEffect(() => {

        if (Array.isArray(account?.portalSettings)) {
            const data = account?.portalSettings?.find((list) => list?.code == "HRC");
            if (data) {
                const cleanHtml = DOMPurify.sanitize(data?.value);
                const decodedHTML = he.decode(cleanHtml);
                //console.log(cleanHtml);
                setNavContent(decodedHTML)
            }
        }

    }, [account]);



    //console.log(home?.url)
    return (
        <>
            <nav className="navbar nav-container d-lg-block justify-content-start bg-white px-3 py-2 ">

                {/*{!isLogin &&*/}
                {/*    <button className="navbar-toggler d-lg-none border-0 shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">*/}
                {/*        <span className="navbar-toggler-icon"></span>*/}
                {/*    </button>*/}
                {/*}*/}
                <div className="d-flex align-items-center  w-100">
                    <div>
                        {account?.logo ? (
                            <>
                                {home?.url?.includes("https://") ? <a href={`${home?.url}`} className="navbar-brand">
                                    <img src={'/doc/getimage?url=' + encodeURIComponent(account?.logo)} height="60" alt="logo" />
                                </a> : <Link className="navbar-brand" to={`${home?.url}`}>
                                    <img src={'/doc/getimage?url=' + encodeURIComponent(account?.logo)} height="60" alt="logo" />
                                </Link>
                                }
                            </>)
                            : (
                                <>
                                    {home?.url?.includes("https://") ?
                                        <a href={`${home?.url}`} className="navbar-brand">
                                            <h3>{account?.name}</h3>
                                        </a> :
                                        <Link className="text-decoration-none text-dark" to={`${home?.url}`}>
                                            <h3>{account?.name}</h3>
                                        </Link>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className="d-none d-md-flex flex-grow-1 justify-content-end" dangerouslySetInnerHTML={{ __html: navContent }}>

                    </div>
                    <div className="align-self-center ms-auto">
                        {!getAuthProfile.getProfileAuth() && window.location.pathname !== "/security/login" && window.location.pathname != "/activateaccount" ?
                            <Link to="/security/login">
                                <button className="btn btn-primary px-4 rounded-pill">{getLanguageContent("signin")}</button>
                            </Link>
                            : ""}


                        {getAuthProfile.getProfileAuth() && (
                            <Link to="/dashboard" title="Dashboard">
                                <svg className="nav_icon" width="28" height="28" role="img"><use xlinkHref="#svg_dashboard" /></svg>
                            </Link>
                        )}
                        {/*{currentEnc?.encounterModeCode !== "INP" && !location.pathname.includes("security") && <CountdownTimer targetDate={currentEnc} />}*/}

                        {/*<ul className="nav pe-3 mb-2 mb-lg-0">*/}
                        {/*<li className={`nav-item ${CheckPath("/")}`}>*/}
                        {/*    <Link to="/" className={`nav-link px-2  text-decoration-none text-dark`}>Home</Link>*/}
                        {/*</li>*/}
                        {/*<li className={`nav-item ${CheckPath("/ourdoctor")}`}>*/}
                        {/*    <Link to="/ourdoctor" className={`nav-link px-2  text-decoration-none text-dark`}>Our Doctors</Link>*/}
                        {/*</li>*/}

                        {/*{*/}
                        {/*    (profile && !isLogin) &&*/}
                        {/*    <li id="dropdownprofile" className="nav-item dropdown">*/}
                        {/*        <a aria-labelledby="dropdownprofile" className="nav-link dropdown-toggle text-dark text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">*/}
                        {/*            */}{/*    <img width="40" height="40" className="rounded-circle img-fluid " src={cropedURL ? cropedURL : (!profileContext?.resProfile?.photoPath) ? require('../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(profileContext?.resProfile?.photoPath)} alt="Profile" />*/}
                        {/*            <img loading="lazy" width="40" height="40" className="rounded-circle img-fluid " src={imageUrl ? imageUrl : profileContext?.resProfile?.photoPath ? "doc/getimage/true?url=" + encodeURIComponent(profileContext?.resProfile?.photoPath) : require('../../asset/images/blankuser.png')} alt="Profile" />*/}
                        {/*        </a>*/}
                        {/*        <ul className={`dropdown-menu dropdown-menu-lg-end `} role="menu" aria-labelledby="dropdownprofile">*/}
                        {/*            {(profile?.resProfile?.firstName && profile?.resProfile?.lastName) &&*/}
                        {/*                <li><span className="dropdown-item">{`${profile?.resProfile?.firstName} ${profile?.resProfile?.lastName}`}</span></li>*/}
                        {/*            }*/}
                        {/*            <li><hr className="dropdown-divider" /></li>*/}
                        {/*            <li><Link className={activemenu == "dashboard" ? "dropdown-item active" : "dropdown-item"} type="button" to="/dashboard">Dashboard</Link></li>*/}
                        {/*            <li><Link className={activemenu == "changepassword" ? "dropdown-item active" : "dropdown-item"} type="button" to="/changepassword">Change password</Link></li>*/}
                        {/*            <li onClick={logout}><span className="dropdown-item">Logout</span></li>*/}
                        {/*        </ul>*/}
                        {/*    </li>*/}
                        {/*}*/}

                        {/*<li id='lang' className="nav-item dropdown">*/}
                        {/*    <a className="nav-link dropdown-toggle m-1"  data-bs-toggle="dropdown" aria-expanded="false">*/}
                        {/*        */}{/*{languageRes?.name}*/}
                        {/*        En*/}
                        {/*    </a>*/}
                        {/*    <ul className="dropdown-menu dropdown-menu-lg-end">*/}
                        {/*        <li onClick={() => getLanguageRes("En")} className="dropdown-item">En</li>*/}
                        {/*        <li onClick={() => getLanguageRes("ES")}className="dropdown-item">ES</li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*    </ul>*/}
                    </div>
                </div>
                {/*<div>*/}
                {/*    <div className="offcanvas offcanvas-start w-50" tabIndex="-1" id="offcanvas" aria-labelledby="offcanvasNavbarLabel">*/}
                {/*        <div className="offcanvas-header">*/}
                {/*            {account?.logo &&*/}
                {/*                <h5 className="offcanvas-title" data-bs-dismiss="offcanvas" id="offcanvasNavbarLabel">*/}
                {/*                    <Link to="/">*/}
                {/*                        */}{/*<img className='img-fluid' width='80' height='50' alt='logo' src={require('../../asset/images/gayawellness.png')} />*/}
                {/*                        <img src={'/doc/getimage?url=' + encodeURIComponent(account?.logo)} className='img-fluid ' width='70' height='40' alt='logo' />*/}
                {/*                    </Link>*/}
                {/*                </h5>*/}
                {/*            }*/}
                {/*            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>*/}
                {/*        </div><hr />*/}
                {/*        <div className="offcanvas-body">*/}
                {/*            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 me-auto mb-2 mb-lg-0">*/}

                {/*                */}{/*<li data-bs-dismiss="offcanvas" className="nav-item">*/}
                {/*                */}{/*    <Link to="/" className="nav-link px-2 link-secondary">Home</Link>*/}
                {/*                */}{/*</li>*/}

                {/*                {*/}
                {/*                    (profile && !isLogin) && (*/}
                {/*                        <>*/}
                {/*                            <li data-bs-dismiss="offcanvas" className="nav-item">*/}
                {/*                                <Link to="/dashboard" className={`nav-link px-2 ${activemenu == "dashboard" ? "active" : ""}`}>Dashboard</Link>*/}
                {/*                            </li>*/}
                {/*                            <li data-bs-dismiss="offcanvas" className="nav-item">*/}
                {/*                                <Link to="/profile" className={`nav-link px-2 ${activemenu == "profile" ? "active" : ""}`}>Profile</Link>*/}
                {/*                            </li>*/}
                {/*                            <li data-bs-dismiss="offcanvas" className="nav-item">*/}
                {/*                                <Link to="/changepassword" className={`nav-link px-2 ${activemenu == "changepassword" ? "active" : ""}`}>Change password</Link>*/}
                {/*                            </li>*/}
                {/*                            <li onClick={logout} data-bs-dismiss="offcanvas" className="nav-item">*/}
                {/*                                <Link to="#" className="nav-link px-2">Logout</Link>*/}
                {/*                            </li>*/}
                {/*                        </>*/}
                {/*                    )*/}
                {/*                }*/}


                {/*                {!profile  &&*/}
                {/*                    <li data-bs-dismiss="offcanvas" className="nav-item">*/}
                {/*                        <Link to="/security/login" className="nav-link px-2 link-secondary">Sign in</Link>*/}
                {/*                    </li>*/}
                {/*                }*/}



                {/*                */}{/*<li className="nav-item">*/}
                {/*                */}{/*    <Link to="/ourdoctor" className="nav-link px-2 link-body-emphasis">Our Doctors</Link>*/}
                {/*                */}{/*</li>*/}
                {/*                */}{/*<li className="nav-item dropdown">*/}
                {/*                */}{/*    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*                */}{/*        En*/}
                {/*                */}{/*    </a>*/}
                {/*                */}{/*    <ul className="dropdown-menu dropdown-menu-start">*/}
                {/*                */}{/*        <li><a className="dropdown-item" href="#">En</a></li>*/}
                {/*                */}{/*        <li><a className="dropdown-item" href="#">Es</a></li>*/}

                {/*                */}{/*    </ul>*/}
                {/*                */}{/*</li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </nav>
        </>
    );
}

export default NavMenu;


