import React, { useContext } from 'react'
import ActionStatus from '../../../../services/utils/actionStatus';
import { dateTimeFormat, getMemberId, getSearchParams, updateSearchParams } from '../../../../services/utils/validation';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import { apiConst } from '../../../../services/constant/constant';
import { serverGet } from '../../../../middleware/http';
import { useState } from 'react';
import ProgressContext from '../../../../services/utils/progress';
import { useEffect } from 'react';

export default function FormList({ toggeleForm, setSelectedFormId, setSelectedFormInputId, formsList, level, handleMultipleForms, showMultipleForms, setFormId, formId, handleEncounterChange }) {
    const { accountDate } = useAccountSettings();

    const selectForm = (list) => {
        setFormId(list?.formId);

        if (list?.isAllowMultiple) {
            handleMultipleForms();
        } else {
            updateSearchParams("frmid", list?.formId);
            setSelectedFormId(list?.formId);

            if (list?.formInputId) {
                updateSearchParams("frminputid", list?.formInputId); // Form Input ID
                setSelectedFormInputId(list?.formInputId);
            }
            toggeleForm();
        }

    }

    return (
        <>
            <div>
                {(level == "patient-forms" && !showMultipleForms && Array.isArray(formsList) && formsList?.length > 0) ? (
                    <div className="mb-5" id="dViewMedication">
                        <div className="row d-none d-md-flex text-secondary">
                            <div className="col">
                                <div className="row">
                                    <div className="col-7">Name</div>

                                    <>
                                        <div className="col-3">Modified by</div>
                                        <div className="col-2">Modified on</div>
                                    </>

                                </div>
                            </div>
                        </div><hr />
                        {formsList?.map((list, i) => (
                            <>
                                <div className="row align-items-center" key={i}>
                                    <div className="col-md-7 mb-2">
                                        <u className="text-primary"><span className={`pointer-class `} onClick={() => selectForm(list)}>{list?.clientTitle}</span></u>
                                    </div>
                                    {level == "patient-forms" && (
                                        <>
                                            {list?.modifiedBy && (
                                                <div className="col-6 col-md-3">
                                                    <span >{list?.modifiedBy}</span>
                                                </div>
                                            )}
                                            {list?.modifiedOn && (
                                                <div className="col-6 col-md-2">
                                                    <span>{dateTimeFormat(list?.modifiedOn, accountDate?.value)}</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <hr className="" />
                            </>
                        ))}
                    </div>
                ) :
                    (showMultipleForms && formId && level != "intake-flow") ? (
                        <MultipleEntryForms handleEncounterChange={handleEncounterChange} id={formId} selectForm={selectForm} level={level} accountDate={accountDate} />
                    ) : (
                        <div>
                            <ActionStatus description={"No records found"} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />
                        </div>
                    )
                }
            </div>
        </>
    )
}


const MultipleEntryForms = ({ id, selectForm, level, accountDate, handleEncounterChange }) => {

    const [formsList, setFormsList] = useState(null);
    const { progParams } = useContext(ProgressContext);
    const [history, setHistory] = useState(false);

    const getFormsList = async () => {

        try {
            progParams(true);
            const _memberId = await getMemberId();
            const _isHistory = level == "encounter-forms" ? 0 : 0
            const _encounterId = level == "encounter-forms" && getSearchParams('eid') ? `&eid=${getSearchParams('eid')}` : ""
            const res = await serverGet(`${apiConst.getMultipleForms}${id}?mid=${_memberId}&ish=${_isHistory}${_encounterId}`);
            if (res?.status == 1) {
                setFormsList(res?.data);
            }
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);
        }
    }


    useEffect(() => {
        getFormsList();

        return (() => {
            setFormsList(null);
        })
    }, [id])
    return (
        <>
            {(Array.isArray(formsList) && formsList?.length > 0) ? (
                <>
                    <FormsList list={formsList?.filter((list) => level == "encounter-forms" ? list?.recordId == getSearchParams('eid')?.toLowerCase() : true)} selectForm={selectForm} accountDate={accountDate} level={level} />
                    {/* 
                    {level == "encounter-forms" && (
                        <>
                            <FormsList list={formsList?.filter((list) => level == "encounter-forms" ? list?.recordId == getSearchParams('eid')?.toLowerCase() : true)} selectForm={selectForm} accountDate={accountDate} level={level} />
                        </>
                    )}

                    {level == "encounter-forms" && (
                        <p className="text-center my-2" onClick={() => setHistory(!history)}>{history ? "Hide history" : "View history"}</p>
                    )}

                    {((level == "encounter-forms" && history) || (level == "patient-forms")) && (
                        <FormsList handleEncounterChange={handleEncounterChange} list={formsList} selectForm={selectForm} accountDate={accountDate} level={level} />
                    )}
                    */}
                </>
            ) : (
                <div>
                    <ActionStatus description={"No records found"} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />
                </div>
            )}
        </>
    )
}



const FormsList = ({ list, level, selectForm, accountDate, handleEncounterChange }) => {
    return (
        <>
            {(Array.isArray(list) && list?.length > 0) ? (
                <>
                    <div className="row d-none d-md-flex text-secondary">
                        <div className="col">
                            <div className="row">
                                <div className="col-6">Entry</div>


                                <div className="col-3">Modified by</div>
                                <div className="col-3">Modified on</div>


                            </div>
                        </div>
                    </div><hr />
                    {list?.map((items, index) => (
                        <>
                            <div className="my-2">
                                <div className="row align-items-center " key={index}>
                                    <div className="col-6">
                                        <u className="text-primary"><span className="pointer-class " onClick={() => selectForm(items)}>Entry #{items?.rowno}</span></u>
                                    </div>

                                    {/*
                                    {(items?.encounterNo && level == "encounter-forms") && (
                                        <div className="col">
                                           <u className="text-primary" onClick={() => handleEncounterChange?   handleEncounterChange(items?.recordId)  : null}><span>{items?.encounterNo}</span></u>
                                            <u className="text-primary"><span>{items?.encounterNo}</span></u>
                                        </div>
                                    )}
                                    */}
                                    {items?.modifiedBy && (
                                        <div className="col-3">
                                            <span >{items?.modifiedBy}</span>
                                        </div>
                                    )}
                                    {items?.modifiedOn && (
                                        <div className="col-3">
                                            <span>{dateTimeFormat(items?.modifiedOn, accountDate?.value)}</span>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <hr className="" />
                        </>
                    ))}
                </>) : (
                <div>
                    <ActionStatus description={"No records found"} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />
                </div>
            )}
        </>
    )
}
