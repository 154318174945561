import React from "react";
import { dateAndTime } from "../../../services/utils/validation";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import ActionStatus from "../../../services/utils/actionStatus";


export default function MessageList({ list, setMsgId, msgId }) {
    const { getLanguageContent } = useLanguageRes();


    const { accountDate } = useAccountSettings();

    return (
        <>
            <div className="card border-0 ">
                <div class="card-header bg-transparent">
                    <h6 className="card-title">{getLanguageContent("msginx")}</h6>
                </div>

                {Array.isArray(list) && list?.length > 0 ? (
                    <div className="card-body">
                        <div className="list-group list-inbox message-list-container">
                            {list?.map((item, i, arr) => (
                                <>                                
                                    <a key={i} onClick={() => setMsgId(item?.messageId)} className={`list-group-item list-group-item-action  ${msgId && item?.messageId ? msgId == item?.messageId ? "active" : "" : ""}`}>
                                        <div className="d-flex w-100 ">
                                            <img className="flex-shrink-0 rounded-circle border me-2" width="50" height="50" src={!item?.photoPath ? require('../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(item?.photoPath)} alt="User Profile" />
                                            <h6 className="flex-grow-1 m-0 ">
                                                {item?.sender}
                                                <small className="d-block pt-2 fw-normal message-truncate-text ">{item?.parentId ? `${getLanguageContent("msgree")}: ${item?.subject}` : item?.subject}</small>
                                            </h6>
                                            {item?.unreadMessageCount ? (
                                                <span className="flex-shrink-0 fs-3 fw-bold">.</span>
                                            ) : ""}
                                        </div>
                                        <div className="text-end"><small className="fs-xs">{dateAndTime(item?.sentOn, accountDate?.value)}</small></div>
                                    </a>
                                </>
                            ))}
                        </div>
                    </div>
                ) : (
                    <>
                        {(list != "loading" && (!list || list?.length < 0)) ?
                            <ActionStatus description={`${getLanguageContent("msgemp")}`} button={""} imageid="#svg_failed" onsyncchange={""} type="search" descriptionClass={"fw-normal"} />
                            : ""
                        }
                    </>
                )}
            </div>
        </>
    )
}

