import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import { serverGet, serverPost } from '../../../../middleware/http';
import { apiConst, resourceConst } from '../../../../services/constant/constant';
import { dateTimeFormat, formValidation, getMemberId, getMsg, timeFormat } from '../../../../services/utils/validation';
import ActionStatus from '../../../../services/utils/actionStatus';
import ProgressContext from '../../../../services/utils/progress';
import NotifyContext from '../../../../services/utils/notify';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';
import { useManageResource } from '../../../../services/Context/ManageResourceContext';

export default function VitalList(props) {

    const [open, setOpen] = useState(false);
    const [showVitalForm, setShowVitalForm] = useState(false);

    const [vitalListData, setVitalListData] = useState([]);
    const [vitalFormId, setvitalFormId] = useState(null);
    const [formDisplayName, setFormDisplayName] = useState(null);

    const member_id = localStorage.getItem("memberId");

    const { accountDate } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    const [resourceAction, setResourceAction] = useState([]);
    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();
    //pannel width inner width <=991 375 to 0   550 +  props?.encounter ? 375 : 0

    const getPanelWidth = () => {
        let panelWidth = window.innerWidth > 767 && window.innerWidth <= 991 ? 0 : (props?.panelWidth == null ? 0 : props?.panelWidth)
        if (panelWidth === 0 && props?.panelWidth > 0)
            return 70;
        else
            return panelWidth;
    }
    const [vitalScreenSize, setVitalScreenSize] = useState({ width: window.innerWidth - (window.innerWidth <= 767 ? 230 : 550 + getPanelWidth()) });
    const VitalWindowSize = () => {
        setVitalScreenSize({ width: window.innerWidth - (window.innerWidth <= 767 ? 230 : 550 + getPanelWidth()) });
    }

    //console.log(props);

    //console.log(window.innerWidth); props.encounter ? 1000 :
    //console.log(vitalScreenSize,"vitalScreenSize"); props.encounter ? 940 :

    useEffect(() => {
        window.addEventListener('resize', VitalWindowSize);
        return () => {
            window.removeEventListener('resize', VitalWindowSize);
        };
    }, []);

    const toggleAll = () => {
        const updatedVitalsData = vitalListData.healthGroup.map((item, i) => {
            return { ...item, isShow: !open };
        });

        setOpen(!open);

        setVitalListData({ ...vitalListData, healthGroup: updatedVitalsData });

    };

    const handleShow = (index) => {

        const updatedVitalsData = vitalListData.healthGroup.map((item, i) =>
            i !== index && !open ? { ...item, isShow: false } : { ...item }
        );

        updatedVitalsData[index].isShow = !updatedVitalsData[index].isShow;

        const close_all = updatedVitalsData?.filter((list) => list?.isShow == true) || [];
        if (close_all?.length == 0)
            setOpen(false);

        setVitalListData({ ...vitalListData, healthGroup: updatedVitalsData });
    }




    const handleFormShow = (index, data, displayName) => {

        setShowVitalForm(!showVitalForm);
        setvitalFormId(data);
        setFormDisplayName(displayName);
    }

    const getList = async () => {
        try {

            const _member_id = await getMemberId();
            const urlSearchParams = new URLSearchParams(window.location.search);
            const encounter_id = urlSearchParams.get('eid');
            const fmid = urlSearchParams.get('fmid');

            progParams(true);
            serverGet(`${apiConst.getvital}${fmid ? fmid : _member_id}${encounter_id ? `?eid=${encounter_id}` : ""}`).then((res) => {
                progParams(false);
                try {
                    if (res.data != null && res.status == 1) {
                        //const health_Group = []
                        if (res.data?.healthGroup?.length > 0) {
                            const findHealthGroup = res.data?.healthGroup?.filter((hg) => hg?.id == vitalFormId) || [];
                            for (const [index, health_Group] of res.data?.healthGroup?.entries()) {
                                if (health_Group?.id == vitalFormId) {
                                    health_Group.isShow = true;
                                } else if (index === 0 && findHealthGroup?.length === 0) {
                                    health_Group.isShow = true;
                                } else {
                                    health_Group.isShow = false;
                                }
                            }
                        }
                        setVitalListData(res?.data);
                    }
                } catch (error) {
                    console.log(error);
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    const bmiCalculator = (value) => {
        if (value < 18.5) {
            return `(${getLanguageContent("vituwe")})`;
        } else if (value >= 18.5 && value <= 24.9) {
            return `(${getLanguageContent("vitnom")})`;
        } else if (value >= 25 && value <= 29.9) {
            return `(${getLanguageContent("vitowt")})`;
        } else if (value >= 30) {
            return `(${getLanguageContent("vitobe")})`;
        } else {
            return "";
        }

    }

    const handleBack = (e) => {
        e.preventDefault();
        setShowVitalForm(!showVitalForm)
    }


    useEffect(() => {
        const _actions = getCurrentResourceAction(props?.tabData?.code, props?.tabData?.parentId);
        setResourceAction(_actions);
    }, [resourceList])

    // console.log(resourceAction);

    useEffect(() => {
        getList();
    }, []);


    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h4 className={`${props.encounter ? "card-title text-uppercase fw-bold fs-6" : ""} m-0`}>{showVitalForm ? getLanguageContent("addnw") : getLanguageContent("vtals")}</h4>
                </div>
                {(!showVitalForm && vitalListData?.healthGroup?.length > 1) && (
                    <button className="btn btn-outline-dark rounded text-end" onClick={toggleAll}>
                        {open ? getLanguageContent("clpse") : getLanguageContent("expnd")}
                    </button>
                )}
            </div>
            {!showVitalForm && vitalListData?.healthGroup?.length > 0 && vitalListData?.healthGroup?.map((list, i) => (
                <div className="card mb-3">
                    <div className="card-title">
                        <div className="d-flex align-items-center p-2">
                            <div className="mx-3 truncate-text flex-grow-1">
                                <h6 onClick={() => handleShow(i)} className="text-primary pointer-class">
                                    {list?.displayName}
                                    <span className="ms-2 text-dark">
                                        {list?.isFormShow || list?.isShow ?
                                            <svg width="7" height="12"><use xlinkHref="#svg_arrowright"></use> </svg>
                                            :
                                            <svg width="12" height="7"><use xlinkHref="#svg_arrowdown"></use> </svg>
                                        }
                                    </span>
                                </h6>
                            </div>
                            {(props.encounter ? actionExists(resourceAction, resourceConst?.actions?.create) && props?.tabData?.write : actionExists(resourceAction, resourceConst?.actions?.create)) && (
                                <div className="py-1">
                                    <button onClick={() => handleFormShow(i, list?.id, list?.displayName)} type="button" className="btn btn-primary d-none d-lg-inline d-md-inline">{getLanguageContent("addnw")}</button>
                                    <svg width="40" height="40" role="img" className="d-inline d-lg-none d-md-none" onClick={() => handleFormShow(i, list?.id, list?.displayName)}><use xlinkHref="#svg_addicon"></use></svg>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card-body py-0" key={i}>
                        {list?.isShow && (
                            vitalListData?.healthMetrics?.length > 0 && vitalListData?.healthMetrics
                                ?.filter((metric) => metric?.healthGroupId == list?.id)
                                .map((metric, index) => {
                                    const noRecordsFound = vitalListData?.patientHealthMetrics
                                        ?.filter((healthmetrics) =>
                                            healthmetrics?.healthMetricId == metric?.id &&
                                            healthmetrics?.healthGroupId == list?.id
                                        )
                                        ?.length === 0;
                                    return (
                                        <React.Fragment key={index}>
                                            {noRecordsFound && index == 0 ? (
                                                <div className="d-flex justify-content-center">
                                                    <ActionStatus
                                                        description={"No records found"}
                                                        button={""}
                                                        imageid="#svg_failed"
                                                        onsyncchange={""}
                                                        type="search"
                                                    />
                                                </div>
                                            ) : (index == 0 &&
                                                <>
                                                    <div className="d-flex pb-3">
                                                        {/*vitals heading left side static column*/}
                                                        <HeaderColumn data={vitalListData?.healthMetrics?.filter((metrics) => metrics?.healthGroupId == list?.id)} />

                                                        {/*vitals takenon & value  right  side scrollable column*/}
                                                        <div className="horizontal-vital pt-2" style={vitalScreenSize}>
                                                            {vitalListData?.healthMetrics?.length > 0 && vitalListData?.healthMetrics?.filter((metrics) => metrics?.healthGroupId == list?.id).map((metric, index) => (
                                                                <>
                                                                    {/*<DateColumn data={item?.Items} index={index == 0 ? true : false} />*/}
                                                                    <div className="d-flex">
                                                                        {vitalListData?.patientHealthMetrics?.length > 0 && vitalListData?.patientHealthMetrics?.filter((healthmetrics) => healthmetrics?.healthMetricId == metric?.id && healthmetrics?.healthGroupId == list?.id).map((metricvalues, indexMetrics) => (
                                                                            <div className="flex-shrink-0" style={{ width: "150px" }}> {/*className={`${indexMetrics % 2 === 0 ? "insurance-list" : ""}`} */}
                                                                                {index == 0 && <div className="fw-semibold text-center" style={{ height: "60px" }}>
                                                                                    {dateTimeFormat(metricvalues?.takenOn, accountDate?.value)}
                                                                                    <br /> {timeFormat(metricvalues?.takenOn)}
                                                                                </div>}
                                                                                <div className="text-center" style={{ height: "50px" }}>{metricvalues?.value ? `${metricvalues?.value}` : "-"}
                                                                                    {metricvalues?.value && metricvalues?.unitCode ? <small className="text-secondary"> {metricvalues?.unitCode}</small> : ""}
                                                                                    {metric?.code == "BMI" ? <small className="d-block">{bmiCalculator(metricvalues?.value)}</small> : ""}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                        )}
                    </div>
                </div>
            ))}
            {showVitalForm && <VitalForm data={vitalFormId} accountDate={accountDate} getList={getList} setShowVitalForm={setShowVitalForm} displayName={formDisplayName} handleBack={handleBack} />}
            {!vitalListData?.healthGroup || vitalListData?.healthGroup?.length == 0 ? <ActionStatus description={"No records found"} button={""} imageid="#svg_failed" onsyncchange={""} type="search" /> : null}
        </>
    )
}

const HeaderColumn = ({ data }) => {
    return (
        <div style={{ width: "170px" }} className="d-flex flex-column vital-h-herder bg-light px-3 pt-2">
            <div className="fw-semibold" style={{ height: "60px" }}></div>
            {data?.map((list, i) => (
                <div className="fw-semibold" style={{ height: "50px" }}>{list?.displayName}</div>
            ))}
        </div>
    )
}


const VitalForm = (props) => {

    const { register, handleSubmit, control, setValue, getValues, clearErrors, setError,  formState: { errors } } = useForm({
        mode: 'all'
    });

    //let allFields = watch(); //  all fields
    // delete allFields?.takenOn; // delete takeon 

    const [vitalForm, setVitalForm] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const member_id = localStorage.getItem("memberId");
    const { getLanguageContent } = useLanguageRes();

    const onSubmit = async (data) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const encounter_id = urlSearchParams.get('eid');

        const formdata = [];
        const vital_Form = [...vitalForm];

        //for (const key in data) {
        //    if (key !== 'takenOn' && (data[key] === null || data[key] == '' || !data[key])) {
        //        return notifyToast(338);
        //    }
        //}
        const _data = { ...data };
        delete _data[`takenOn`];

        if (Object.values(_data).some(value => !!value)) {
        } else {
            return notifyToast(338);
        }

        vital_Form?.forEach(item => {
            const feetKey = `${item.id}_feet`;
            const inchKey = `${item.id}_inch`;

            if (data[feetKey] !== undefined || data[inchKey] !== undefined) {
                const feetValue = data[feetKey] || 0; 
                const inchValue = data[inchKey] || 0; 

                item.value = `${feetValue}'${inchValue}"`;
            } else {
                item.value = data[item.id];
            }
        });

        vital_Form?.map((list) => {
            const form_data = {
                value: list?.value,
                takenOn: new Date(startDate), //Moment(data?.takenOn).format('YYYY-MM-DDTHH:mm:ss') ,
                healthGroupMetricId: list?.healthGroupMetricId,
                unitId: list?.unitId
            }
            if (encounter_id && encounter_id !== "") {
                form_data.encounterId = encounter_id;
            }
            formdata.push(form_data);
        })

        progParams(true);
        serverPost(`${apiConst.vitalsave}${member_id}`, formdata).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(res.messageCode[0]);
                    props?.getList();
                    props.setShowVitalForm(false);
                }
                else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })
    };


    const getForm = useCallback(() => {
        progParams(true);
        serverGet(`${apiConst.getvitalform}${props?.data}`).then((res) => {
            progParams(false);
            try {
                if (res.data != null && res.status == 1) {
                    setVitalForm(res.data);
                }
            } catch (error) {
                console.log(error);
            }
        })


    }, [props?.data]);


    useEffect(() => {
        if (props?.data) {
            getForm();
            setValue("takenOn", startDate)
        }
        return () => {
            setVitalForm([]);
        };
    }, []);

    return (

        <>

            {(Array.isArray(vitalForm) && vitalForm?.length > 0) && (
                <>
                    <div className="card border-0">
                        <div className="card-body">
                            <h4 className="mb-4">{props?.displayName}</h4>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="row align-items-center g-2">
                                    <div className="mb-3 col-xl-7" >
                                        <label className="form-label" htmlFor={`takenOn`}>{getLanguageContent("rcdon")}<span className="text-danger star">*</span></label>
                                        <Controller
                                            name={"takenOn"}
                                            control={control}

                                            rules={{ required: getMsg(301) }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    autoFocus={true}
                                                    name={"takenOn"}
                                                    id={"takenOn"}
                                                    className="form-control"
                                                    maxDate={new Date()}
                                                    placeholderText={getLanguageContent("rcdon")}
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                        setStartDate(date);
                                                    }}
                                                    timeInputLabel="Time"
                                                    dateFormat={props?.accountDate?.value ? `${props?.accountDate?.value?.toLowerCase()?.replace(/mm/, "MM")} h:mm aa` : "MM/dd/yyyy h:mm aa"}
                                                    wrapperClassName="w-100"
                                                    showTimeInput
                                                    timeFormat="h:mm"
                                                />
                                            )}
                                        />
                                        {(errors["takenOn"]?.message && !startDate) && <p className="text-danger">{errors["takenOn"]?.message}</p>}
                                    </div>
                                </div>

                                {vitalForm?.map((list, i) => (
                                    <>
                                        {list?.controlTypeId == 1 && list?.unitCode == "ft" ?
                                            <>
                                                <div className="row align-items-center g-2" key={i}>
                                                    <label htmlFor={`${list?.displayName}${list?.id}`} className="form-label">
                                                        {list?.displayName}
                                                        {list?.unitCode && <span className="d-lg-none">( {list?.unitCode} )</span>}
                                                        {list?.isRequired && <span className="text-danger">*</span>}
                                                    </label>

                                                    <div className="col-xl-5 col-lg-5">
                                                        <Controller
                                                            name={`${list?.id}_feet`}
                                                            control={control}
                                                            rules={{
                                                                required: !getValues(`${list?.id}_inch`) ? getMsg(301) : false,
                                                                pattern: list?.dataTypeId === 2 ? formValidation.number.pattern :
                                                                    list?.dataTypeId === 3 ? formValidation.decimalnumbers.pattern : null,
                                                            }}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name={`${list?.id}_feet`}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        const feetValue = e.target.value;
                                                                        const inchValue = getValues(`${list?.id}_inch`);

                                                                        if (feetValue || inchValue) {
                                                                            clearErrors(`${list?.id}_feet`);
                                                                            clearErrors(`${list?.id}_inch`);
                                                                        } else {
                                                                            setError(`${list?.id}_feet`, { message: getMsg(301) });
                                                                            setError(`${list?.id}_inch`, { message: getMsg(301) });
                                                                        }
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control body-secondary-color"
                                                                    id={`${list?.displayName}${list?.id}`}
                                                                />)}
                                                        />
                                                    </div>

                                                    <div className="col-1 mb-3 d-none d-lg-block mt-4">
                                                        {list?.unitCode}
                                                    </div>

                                                    <div className="col-xl-5 col-lg-5">                                                    
                                                        <Controller
                                                            name={`${list?.id}_inch`}
                                                            control={control}
                                                            rules={{
                                                                required: !getValues(`${list?.id}_feet`) ? getMsg(301) : false,
                                                                pattern: list?.dataTypeId === 2 ? formValidation.number.pattern :
                                                                    list?.dataTypeId === 3 ? formValidation.decimalnumbers.pattern : null,
                                                            }}
                                                            render={({ field }) => (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name={`${list?.id}_inch`}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        const inchValue = e.target.value;
                                                                        const feetValue = getValues(`${list?.id}_feet`);

                                                                        if (inchValue || feetValue) {
                                                                            clearErrors(`${list?.id}_feet`);
                                                                            clearErrors(`${list?.id}_inch`);
                                                                        } else {
                                                                            setError(`${list?.id}_inch`, { message: getMsg(301) });
                                                                            setError(`${list?.id}_feet`, { message: getMsg(301) });
                                                                        }
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control  body-secondary-color"
                                                                    id={list?.id}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="col-1 mb-3 d-none d-lg-block mt-4">
                                                        in
                                                    </div>

                                                    <div>
                                                        <span>
                                                            {(errors[`${list?.id}_feet`]?.message || errors[`${list?.id}_inch`]?.message) && (
                                                                <p className="text-danger">
                                                                    {errors[`${list?.id}_feet`]?.message || errors[`${list?.id}_inch`]?.message}
                                                                </p>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>



                                        </>
                                            : list?.controlTypeId == 1 && (
                                            <div className="row align-items-center g-2" key={i}>
                                                <div className="mb-3 col-xl-7">
                                                    <label htmlFor={`${list?.displayName}${list?.id}`} className="form-label">{list?.displayName} {list?.unitCode ? <span className="d-lg-none">( {list?.unitCode} )</span> : ""}{list?.isRequired ? <span className="text-danger">*</span> : ""}</label>
                                                    <input type="text" className="form-control body-secondary-color" id={`${list?.displayName}${list?.id}`}
                                                        placeholder={`Enter ${list?.displayName}`}
                                                        {...register(list?.id, {
                                                            required: list?.isRequired ? getMsg(301) : false,
                                                            pattern:
                                                                list?.dataTypeId == 2 ? formValidation.number.pattern :
                                                                    list?.dataTypeId == 3 ? formValidation.decimalnumbers.pattern : null
                                                        })}
                                                    />
                                                    {errors[list?.id]?.message && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                                </div>
                                                <div className="col-2 d-none d-lg-block mt-4">
                                                    {list?.unitCode}
                                                </div>
                                            </div>
                                            )}
                                    </>
                                ))}
                                <div className="row">
                                    <div className="mb-5 col-xl-7 text-end mb-2">
                                        <button className="btn btn-secondary btn-lg me-2" onClick={(e) => props?.handleBack(e)}>{getLanguageContent("bck")}</button>
                                        <button type="submit" className="btn btn-primary btn-lg">{getLanguageContent("sve")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

