import React, { useContext, useState } from "react"
import { dateFormat, getHtmlContent, getMemberId, getSearchParams } from "../../../../../services/utils/validation";
import { serverGet } from "../../../../../middleware/http";
import { apiConst } from "../../../../../services/constant/constant";
import { useEffect } from "react";
import { getHeadingClass } from "../customForm";
import { useAccountSettings } from "../../../../../services/Context/AccSettingContext";
import ProgressContext from "../../../../../services/utils/progress";

export default function SummaryForm({ selectedFormId, formInputId, handleFormTitle }) {

    const [formData, setFormData] = useState([]);
    const { accountDate } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);



    const getFormFields = async (id) => {
        try {
            progParams(true);
            const _memberId = await getMemberId();
            let encounter_id = getSearchParams("eid");
            let fmid = getSearchParams("fmid");
            const res = await serverGet(`${apiConst.getformlist}${id}?mid=${fmid ? fmid : _memberId}${encounter_id ? `&eid=${encounter_id}` : ""}${formInputId ? `&fipid=${formInputId}` : ""}`)
            progParams(false);
            if (res?.status == 1 && res?.data) {
                setFormData(res?.data)
            }
        } catch (e) {
            console.log(e)
        }
    }



    useEffect(() => {
        //getForms();
        if (selectedFormId) {
            getFormFields(selectedFormId);

        }
    }, [])

    return (
        <>
            {(Array.isArray(formData) && formData.some(list => list.value && list.value?.trim() != "")) && (
                <>
                    {handleFormTitle()}
                    <h4>{formData[0]?.formTitle}</h4>
                    {formData?.map((list, index, array) => (
                        <>
                            {(list?.controlId == 1 && list?.value) && (
                                <RenderQuestionAndAns isHtml={list?.isHtml} question={list?.clientTitle} answer={list?.value} />
                            )}

                            {(list?.controlId == 2 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderCheckBox arr={array} isHtml={list?.isHtml} id={list.fieldId} title={list?.clientTitle} />
                                </>
                            )}

                            {list?.controlId == 3 ?
                                <div className="my-4">
                                    <p className='fw-bold'>{list?.clientTitle}</p>
                                </div> : ''
                            }

                            {list?.controlId == 4 ?
                                <div className="border border-2 rounded rounded-2 p-4">
                                    <div className={`${list?.styleId == '3' ? "scroll-list-container bg-light text-dark p-3" : ''}`} dangerouslySetInnerHTML={{ __html: list?.fieldOption }}>
                                    </div>
                                </div>
                                : ""
                            }

                            {(list?.controlId == 5 && list?.value) && (
                                <>
                                    <RenderQuestionAndAns question={list?.clientTitle} answer={dateFormat(list?.value, accountDate?.value)} />
                                </>
                            )}

                            {(list?.controlId == 6 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderRadio arr={array} isHtml={list?.isHtml} id={list.fieldId} />
                                </>
                            )}

                            {(list?.controlId == 7 && list?.value) && (
                                <>
                                    <RenderQuestionAndAns question={list?.clientTitle} answer={list?.value} />
                                </>
                            )}

                            {(list?.controlId == 8 && list?.value) && (
                                <RenderYearAndMonth question={list?.clientTitle} answer={list?.value} />
                            )}

                            {
                                ((list?.controlId == 9 || list?.controlId == 10 || list?.controlId == 11) && array[index + 1]?.value != "") ?
                                    <div key={list?.id} className="mb-2">
                                        <span className={`${getHeadingClass(list?.controlId)} fw-normal`}>{list?.clientTitle}</span>
                                    </div> : ''
                            }

                            {(list?.controlId == 12 && list?.clientTitle && list?.clientTitle?.trim()) ?
                                <div key={list?.id} className="mb-2" dangerouslySetInnerHTML={{ __html: getHtmlContent(list?.clientTitle) }}>
                                </div> : ''
                            }

                            {(list?.controlId == 13 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderRadio arr={array} isHtml={list?.isHtml} id={list.fieldId} />
                                </>
                            )}

                            {(list?.controlId == 14 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderRadio arr={array} isHtml={list?.isHtml} id={list.fieldId} />
                                </>
                            )}

                            {(list?.controlId == 15 && list?.value) && (
                                <RenderQuestionAndAns isHtml={list?.isHtml} question={list?.clientTitle} answer={list?.value} />
                            )}

                            {(list?.controlId == 16 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderCheckBox arr={array} isHtml={list?.isHtml} id={list.fieldId} title={list?.clientTitle} />
                                </>
                            )}

                            {(list?.controlId == 17 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderCheckBoxTextArea arr={array} isHtml={list?.isHtml} id={list.fieldId} title={list?.clientTitle} />
                                </>
                            )}

                            {(list?.controlId == 18 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderCheckBox arr={array} isHtml={list?.isHtml} id={list.fieldId} title={list?.clientTitle} />
                                </>
                            )}

                            {(list?.controlId == 19 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderCheckBox arr={array} isHtml={list?.isHtml} id={list.fieldId} title={list?.clientTitle} />
                                </>
                            )}

                        </>
                    ))}
                    <hr />
                </>
            )
            }
        </>
    )
}


const RenderYearAndMonth = ({ question, answer }) => {

    const [year, month] = answer?.split("~")

    return (
        <>
            <div className="mb-2">
                <span>{question}</span><br />
                <span className="fw-bold">{year ? `${year} Year` : ""} {month ? `${month} Month` : ""}</span>
            </div>
        </>
    )
}



const RenderCheckBox = ({ arr, id, title, isHtml }) => {
    const list = arr?.filter(item => item.fieldId == id);

    if (!list?.length) return null;

    const { clientTitle, value } = list[0];
    const options = value.split("~").map(child =>
        list.find(v => v.fieldOptionId == child)?.fieldOption
    ).filter(Boolean).join(', ');

    return (
        <RenderQuestionAndAns question={clientTitle} isHtml={isHtml} answer={title && options ? options : options ? "Agreed" : ""} />
    )
};

const RenderCheckBoxTextArea = ({ arr, id, title, isHtml }) => {

    const [data, setData] = useState(null);


    useEffect(() => {
        const temp2 = {};

        const list = arr?.filter(item => {
            if (item.fieldId == id) {
                temp2[item.fieldOptionId] = item.fieldOption;
                return true;
            }
            return false;
        });

        if (!list?.length) return null;
        const { value } = list[0];
        const temp = [];
        value.split("~")?.map((child) => {
            const [option, textAreaValue] = child.split("|")
            temp.push({ option: temp2[option], value: textAreaValue })
        })

        setData(temp);

    }, [])

    return (
        <>
            {(Array.isArray(data) && data?.length > 0) && (
                <>
                    <div className="mb-2">
                        {isHtml ? <span dangerouslySetInnerHTML={{ __html: title }}></span> : <span>{title}</span>}<br />

                        {data?.map((item) => (
                            <>
                                <p className="mb-2"><span className="fw-bold">{item?.option}</span>  {item?.value && <span className="ms-2 text-secondary">({item?.value})</span>}</p>
                            </>
                        ))}
                    </div>
                </>
            )}
        </>
    )
}



const RenderRadio = ({ arr, id, isHtml }) => {
    const list = arr?.filter(item => item.fieldId == id);

    if (!list?.length) return null;
    const { clientTitle, value } = list[0];


    return (
        <RenderQuestionAndAns question={clientTitle} isHtml={isHtml} answer={list.find(v => v.fieldOptionId == value)?.fieldOption} />

    )
}


const RenderQuestionAndAns = ({ question, answer, isHtml }) => {
    return (
        <>
            {answer && (
                <div className="mb-2">
                    {isHtml ? <span dangerouslySetInnerHTML={{ __html: question }}></span> : <span>{question}</span>}<br />
                    <span className="fw-bold">{answer}</span>
                </div>
            )}
        </>
    )
}


